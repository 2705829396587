<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="ps">
  <i class="icon i icon-alarm-level3" :class="source.class"></i>
  <span class="name">{{source.name}}</span>
  <span class="icon" focusable="false" @click="highlight()" :title="$t('common.highlight')"><span class="fa-icon crosshair"></span></span>
  <span class="right">{{datestr}}</span>
</div>
</template>

<style lang="less">
@import '/global';
.ps{
  height: 20px;

.crosshair {
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' width='16' height='16' viewBox='0 0 512 512'%3E%3Cg%3E%3Cpath d='M500 224c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-30.4c-13.9 93.7-88 167.7-181.6 181.6v30.4c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-30.4c-93.7-13.9-167.7-88-181.6-181.6h-30.4c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h30.4c13.9-93.7 88-167.7 181.6-181.6v-30.4c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v30.4c93.7 13.9 167.7 88 181.6 181.6h30.4zM288 404.6c58.2-12.4 104.2-58.5 116.6-116.6h-40.6c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40.6c-12.4-58.2-58.5-104.2-116.6-116.6v40.6c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40.6c-58.2 12.4-104.2 58.5-116.6 116.6h40.6c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40.6c12.4 58.2 58.5 104.2 116.6 116.6v-40.6c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40.6zM288 256c0 17.7-14.3 32-32 32s-32-14.3-32-32 14.3-32 32-32 32 14.3 32 32z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.3;
    vertical-align: -0.6px;
  }

  background-color: @color-base-bg;
  &:hover{
    background-color: #dadada;
  }
  .i{
    margin-right: 5px;
    &.unknown{color: @color-state-unknown;}
    &.occupied{color: @color-state-occupied;}
    &.free{color: @color-state-free;}
    &.closedoff{color: @color-state-closedoff;}
  }
  .right{
    float: right;
  }
  .icon {
    cursor: pointer;
    vertical-align: bottom;
    color: #aaa;
  }
  .name {
    margin-right: 5px;
  }
}
</style>
<script>

import model from '/model';
import { formatDistanceToNow } from 'date-fns'
import { en, hu } from 'date-fns/locale';

const fns_locales = {en, hu};
let fns_locale = fns_locales[window.localStorage.getItem('lang')];
if (!fns_locale) {
  fns_locale = en;
}

export default {
  name: 'current-state',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data(){
    return {
      timer: null,
      highlightTimer: null,
      datestr: ''
    };
  },
  mounted(){
    this.genStr();
    this.timer = setInterval(this.genStr, 30000);
  },
  watch: {
    'ps._state_changed_at': function(){
      this.genStr();
    }
  },
  destroyed () {
    clearInterval(this.timer);
  },
  methods: {
    genStr(){
      this.datestr = formatDistanceToNow(this.source._state_changed_at, { addSuffix: true, locale: fns_locale })
    },
    highlight() {
      model.parkingspaces.highlightedId = this.source.id;
      clearTimeout(this.highlightTimer);
      this.highlightTimer = setTimeout(function() {
        model.parkingspaces.highlightedId = undefined;
      }, 3000);
    }
  }
};
</script>
