<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="daypicker" @mousewheel.prevent="scroll($event)">
    <div class="arrow up" @click="dec"></div>
    <div><span>{{d}}</span></div>
    <div class="arrow down" @click="inc"></div>
  </div>
</template>

<style lang="less" scoped>
@import '/global';

.daypicker{
  //border: 1px solid @color-border;
  padding: 2px 5px;
  background-color: #fff;
  text-align: center;

  display: flex;
  flex-direction: column;
  margin-top: -45px;
  width: 50px;

  .arrow{
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 20px 15px;
    &.up{
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 7px solid #000;
    }
    &.down{
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 7px solid #000;
    }
  }  
}
</style>

<script>
export default{
  name: 'daypicker',
  props: ['value', 'labels'],
  computed: {
    d(){
      return this.labels[this.value];
    }
  },
  methods: {
    inc(){
      if(this.value > 0)
        this.$emit('input', this.value - 1);
    },
    dec(){
      if(this.value < this.labels.length - 1)
        this.$emit('input', this.value + 1);
    },
    scroll(e){
      if(e.wheelDelta > 0){
        this.dec();
      }else{
        this.inc();
      }
    }
  }
};
</script>
