<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-profiles-loading">
  <div class="box">
    <div class="header">
      <div class="text">{{$t('common.loading_hours_profiles')}}</div>
      <div @click="m.lhps.addP()" class="btn pushright"><i class="icon icon-plus"></i></div>
    </div>
  </div>
  <profile v-for="p in m.lhps.list" :owner="m.lhps" :p="p" :labels="$t('days_of_week')"></profile>
</div>
</template>

<style lang="less">
@import '/global';

.settings-profiles-loading{
  &>.box>.header>.text{
    font-size: 18px;
  }
}

</style>

<script>
import Profile from './profile.vue';
import m from '/model';
import VSelect from 'vue-select';

export default{
  name: 'settings-profiles-loading',
  components: { Profile, VSelect },
  data(){
    return{
      m
    };
  }
};
</script>
