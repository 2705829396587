<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="date-field">
    <input type="text" ref="fp" :disabled="disabled">
    <button v-if="showreset && value" @click="reset()" :disabled="disabled"><i class="icon icon-cross"></i></button>
  </div>
</template>

<style>
  .date-field {
    display: flex;
  }

  .date-field input {
    flex: 1 1 auto !important;
    line-height: 31px;
    border: 1px solid rgba(60,60,60,.26);
    font-family: Vodafone, Helvetica, Arial, sans-serif;
    font-size: 16px;
  }

  .date-field button {
    text-align: center;
    line-height: 31px;
    margin-left: 4px;
    background: grey;
    color: white;
    border: none;
  }
  .date-field button i {
    margin: 0 !important;
  }
</style>

<script>
import moment from 'moment';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import { Hungarian } from "flatpickr/dist/l10n/hu.js"
import { english } from "flatpickr/dist/l10n/default.js"

const locales = {
  en: english,
  hu: Hungarian
};

const dateTimeFormats = {
  en: "M j, Y, h:i K",
  hu: "Y.m.d. H:i"
};

const dateFormats = {
  // en: "M j, Y",
  // hu: "Y.m.d."
  en: "Y-m-d",
  hu: "Y-m-d"
};

export default {
  name: 'datetimepicker',
  props: ['value', 'datestringmode', 'limittoday', 'showreset', 'disabled'],
  // props: {
  //   value: Object,
  //   datestringmode: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  mounted(){
    var v = this;
    let dt;
    if (v.value > "") {
      dt = new Date(v.value);
    } else {
      dt = null;
    }
    
    // console.log('datepicker init value', v.value, 'date', dt)
    this._fp = flatpickr(this.$refs.fp, {
      locale: locales[this.$i18n.locale],
      maxDate: this.limittoday != true ? null : (new Date()),
      enableTime: this.datestringmode != true,
      dateFormat: this.datestringmode != true ? dateTimeFormats[this.$i18n.locale] : dateFormats[this.$i18n.locale],
      time_24hr: true,
      defaultDate: dt,
      onValueUpdate(){
        v.$emit('input', this.selectedDates[0]);
      }
    });
  },
  methods: {
    reset: function (){
      this._fp.setDate(null);
      this.$emit('input', null);
    }
  },
  watch: {
    value(val, old){
      this._fp.setDate(val);
    }
  },
  beforeDestroy(){
    // Remove floating ui html from body
    this._fp.destroy();
  }
};
</script>
