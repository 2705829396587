/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import {urls, debug} from '../../config.yml';

import axios from 'axios';

import Vue from 'vue';
import translations from '../translations.js';

var access = '';
var refresh = '';
var exp = null;

function sleep(ms){
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function redirect(to){
  if(!debug || confirm('Redirect to ' + to)){
    window.location.replace(to);
    await sleep(5000);
  }
}

function parseExp(token){
  return JSON.parse(atob(token.split('.')[1])).exp;
}

function load(){
  var jwt = window.localStorage.getItem('jwt');
  if(jwt == null)
    throw null;
  jwt = JSON.parse(jwt);
  if(!('access' in jwt) || !('refresh' in jwt))
    throw null;
  access = jwt.access;
  refresh = jwt.refresh;
  exp = parseExp(access);
}

function save(){
  window.localStorage.setItem('jwt', JSON.stringify({access, refresh}));
}

var refresher = null;

async function refreshtoken(){
  console.log('Refreshing token');
  try{
    var res = await axios.post(urls.backend + '/api/token/refresh/', {refresh});
    access = res.data.access;
    exp = parseExp(access);
    save();
  }catch(e){
    console.log('token refresh failed', e);
    await logout();
  }
  refresher = null;
}

async function getToken(forceUpdate = false){
  if(exp * 1000 < (new Date()).valueOf() || forceUpdate){
    refresher = (refresher || refreshtoken());
    await refresher;
    return access;
  }else{
    return access;
  }
}

async function logout(){
  window.localStorage.removeItem('jwt');
  await redirect(urls.auth);
}

const api = axios.create({
  baseURL: urls.backend + '/api'
});

api.getall = async function(url, config){
  var res = await api.get(url, config);
  let next = res.data.next;
  if (next != null) {
    next = res.data.next.replace(/^https?:\/\//, '//')
  }
  return res.data.results.concat(next != null ? await api.getall(next, config) : []);
}

api.logout = logout;

export default async function(model){
  try{
    load();
  }catch(e){
    console.log('load failed', e);
    await logout();
  }
  api.interceptors.request.use(
    async function(config){
      if('companies' in model){
        config.headers['X-Company'] = model.companies.selected.id;
      }
      config.headers['Authorization'] = 'Bearer ' + await getToken();
      config.headers['X-App-Instance'] = window.localStorage.appInstanceId;
      return config;
    },
    async function(error){
      return error;
    }
  );
  api.interceptors.response.use(
    res=>res,
    async function(error){
      if (typeof error.response !== 'undefined') {
        const {config, response: {status, data}} = error;
        // console.log('403 details', status, data);
        if(
			(status === 403 && data.code == 'token_not_valid') || 
			(status === 401 && data.message == 'invalid or expired jwt')
        ){
          config.headers['Authorization'] = 'Bearer ' + await getToken(true);
          config.headers['X-App-Instance'] = window.localStorage.appInstanceId;
          return axios(config);
        }
      }

      const locale = window.localStorage.getItem('lang');            
      let lang = translations[locale];

      var errorText = lang.errormessages.api_req_failed + error.message; 
      if (typeof error.response !== 'undefined') {
        if (typeof error.response.data !== 'undefined') {
          // Has {message:xxx}
          if (typeof error.response.data.message !== 'undefined') {
            errorText = lang.errormessages.api_req_failed + error.response.data.message; 
          // Unknown format
          } else {
            let message = typeof error.response.data != 'string' ? JSON.stringify(error.response.data, null, 1) : error.response.data;
            message = message.substr(0, 350).replace(/</g, '').replace(/>/g, '');
            errorText = lang.errormessages.api_req_failed + error.response.status + ' ' + message;
          }
        }

        // Request URL
        if (typeof error.response.config !== 'undefined') {
            errorText = errorText.replace('%%', error.response.config.method.toUpperCase() + ' ' + error.response.config.url.replace(/\?.*?$/, ''));
        } else {
          errorText = errorText.replace('%%', '');
        }

        if (error.response.status === 429) {
            errorText = lang.errormessages.stat_overlap;
        }
      } else {
        if (typeof error.config !== 'undefined' && error.config.url.match(/parkingquery/)) {
          // TODO: Find a better way
          errorText = lang.errormessages.stat_timeout;
        } else {
          errorText = errorText.replace('%%', '');
        }
      }

      Vue.toasted.error(errorText, {icon: 'exclamation-triangle'});
      console.log('api request error', errorText, error.response, error.config, JSON.stringify(error));

      if (typeof error.response !== 'undefined') {
        return Promise.reject(error.response);
      } else {
        return Promise.reject();
      }
    }
  );
  return api;
};
