/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


class Profile{
  constructor(o){
    Object.assign(this, o);
    this.details = [];
  }
}

function timeStrToMins(timestr){ // Convert string representation of a time to minutes in 24h
  var t = timestr.split(':');
  return parseInt(t[0]) * 60 + parseInt(t[1]);
}

function minsToTimeStr(mins){
  if (mins >= 1439) {
    return '23:59:59';
  } else {
    return `${Math.floor(mins / 60)}:${mins % 60}:00`;
  }
}

class ProfileDetail{
  // ID 0: NEW, not yet synced with server
  // else: detail, which is present in db as well
  // Model uses string for time in 'hh:mm:ss' form,
  // and days of week are indexed from 1.
  constructor(o, profile){
    Object.assign(this, o);
    this.day_of_week--;
    this.start = timeStrToMins(this.start);
    this.end = timeStrToMins(this.end);
    this.freeze();
    this.profile = profile;
    this.deleted = false;
  }
  freeze(){
    this._saved = Object.freeze({
      day_of_week: this.day_of_week,
      start: this.start,
      end: this.end
    });
  }
  restore(){ // Revert to the synced state, or delete if it does not exist
    if(this.id == 0){
      this.delete();
    }else{
      this.day_of_week = this._saved.day_of_week;
      this.start = this._saved.start;
      this.end = this._saved.end;
    }
  }
  delete(){
    this.deleted = true;
    this.start = 0;
    this.end = 0;
  }
}


export default async function(profiles_endpoint, details_endpoint, model){
  var list = [];
  function getById(id){
    return list.find(i=>(i.id==id));
  }

  var res = await model.api.get(profiles_endpoint);
  res.data.forEach((r)=>{
    var pp = new Profile(r);
    list.push(pp);
  });

  res = await model.api.get(details_endpoint);
  res.data.forEach((d)=>{
    var p = getById(d.profile);
    p.details.push(new ProfileDetail(d, p));
  });

  return {
    list,
    getById,
    async addP(){
      var res = await model.api.post(profiles_endpoint, {
        name: 'Profile-' + (new Date()).toISOString().replace(/[T:]/g, '-').substr(0, 19)
      });
      var pp = new Profile(res.data, model);
      list.push(pp);
    },
    async deleteP(p){
      let error;
      try{
        await model.api.delete(profiles_endpoint + p.id + '/');
        list.splice(list.indexOf(p), 1);
      }catch(e){
        error = e;
        console.log('Can\'t delete');
      }
      return !error;
    },
    async saveP(p, newname){
      let error;
      await model.api.patch(profiles_endpoint + p.id + '/', {name: newname}).then(res => {
        p.name = res.data.name;

        p.details.forEach(d=>{
          if(!d.deleted){
            if(d.id == 0){
              model.api.post(details_endpoint, {
                profile: p.id,
                day_of_week: d.day_of_week + 1,
                start: minsToTimeStr(d.start),
                end: minsToTimeStr(d.end)
              }).then(res=>{
                d.id = res.data.id;
                d.freeze();
              });
            }else if(d.day_of_week != d._saved.day_of_week || d.start != d._saved.start || d.end != d._saved.end){
              model.api.patch(details_endpoint + d.id + '/', {
                day_of_week: d.day_of_week + 1,
                start: minsToTimeStr(d.start),
                end: minsToTimeStr(d.end)
              }).then(res=>{
                d.freeze();
              });
            }
          }else if(d.id > 0){
            model.api.delete(details_endpoint + d.id + '/')
            .then(res=>{
              d.id = 0;
            });
          }
        });
      }).catch(e => {
        error = e;
      });
      return !error;
    },


    addDetail(p){
      p.details.push(new ProfileDetail({
        id: 0,
        day_of_week: 1,
        start: '08:00:00',
        end: '12:00:00'
      }, p));
      return p.details.length - 1;
    },
    deleteDetail(d){
      d.delete();
    },
  };
}
