export default function(){
  function getStatsBlocks(sum, chart){

    // Parking reports and filters
    const reportTypes = ["avg_length", "count", "utilization"];
    const filterTypes = ["metered", "non_metered", "all"];
    const reportTransforms = {
      // Convert seconds to minutes
      avg_length: data => Math.round(data / 60),
      // Trim percentage precision to 3 digits
      utilization: data => data.toFixed(3)
    };

    // Parking histyogram time units
    const timeUnits = [
      {
        label: "hónap",
        seconds: 2592000 // 30 days
      },
      {
        label: "hét",
        seconds: 604800 // 7 days
      },
      {
        label: "nap",
        seconds: 86400 // 1 day
      },
      {
        label: "óra",
        seconds: 3600 // 1 hour
      },
      {
        label: "perc",
        seconds: 60 // 1 minute
      }
    ];
    const histRangeSeconds = sum.params.hist_range_size;
    let histTimeUnit = timeUnits[timeUnits.length - 1];
    // Choose largest matching with 0 modulo
    for (const i in timeUnits) {
      const timeUnit = timeUnits[i];
      if (histRangeSeconds % timeUnit.seconds === 0) {
        histTimeUnit = timeUnit;
        break;
      }
    }

    // Histogram label format: seconds -> From unit - To unit
    const formatHistLabel = seconds =>
      // From
      seconds / histTimeUnit.seconds +
      " " +
      histTimeUnit.label +
      " - " +
      // To
      (seconds + histRangeSeconds) / histTimeUnit.seconds +
      " " +
      histTimeUnit.label;

    const formatDate = isoDate =>
      isoDate.replace("T", " ").replace(/\+\d+:\d+$/g, "");

    let params = [
      formatDate(sum.params.start),
      formatDate(sum.params.end),
      formatDate(sum.params.run_at),
      sum.params.company
    ];

    let summaries = [];
    for (const reportType of reportTypes) {
      let summary = [];
      for (const filterType of filterTypes) {
        let data = sum[reportType][filterType];
        if (reportTransforms.hasOwnProperty(reportType)) {
          data = reportTransforms[reportType](data);
        }
        summary.push(data);
      }
      summaries.push(summary);
    }

    let histograms = [];
    if (sum.histogram) {
      for (const filterType of filterTypes) {
        let data = sum.histogram[filterType];

        let histogram = [];
        for (const i in data.values) {
          const seconds = data.labels[i];
          const value = data.values[i];
          // Tab separated columns: Range label | Range value
          histogram.push([formatHistLabel(seconds), value]);
        }

        histograms.push(histogram);
      }
    }

    
    let charts = [];
    if (chart) {
      for (const row of chart) {
        const splitDate = str => str.substr(0, 16).split(/(<?(?: |\d{4}-))/g);
        const start = splitDate(formatDate(row.start));
        const end = splitDate(formatDate(row.end));
        // Remove same start sequence
        for (const i in end) {
          if (end[i] === start[i]) {
            end[i] = "";
          } else {
            break;
          }
        }
        const tooltip = start.join("") + " - " + end.join("");

        let cells = [row.label, tooltip];
        for (const reportType of reportTypes) {
          for (const filterType of filterTypes) {
            let data = row[reportType][filterType];
            if (reportTransforms.hasOwnProperty(reportType)) {
              data = reportTransforms[reportType](data);
            }
            cells.push(data);
          }
        }

        charts.push(cells);
      }
    }

    return {charts, histograms, summaries, params};

  }

  return {getStatsBlocks};
}