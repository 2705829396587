/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


export default class C{
  constructor(ps, list, selected=false){
    this.ps = ps;
    this.selected = selected;
    this.list = list;
    list.parking_spaces.push(this);
  }
  releaseList(){
    var l = this.list.parking_spaces;
    l.splice(l.indexOf(this), 1);
    this.list.selected = this.list.parking_spaces.every(c=>c.selected);
    this.list = undefined;
  }
  assignList(l){
    l.parking_spaces.push(this);
    this.list = l;
    this.list.selected = this.list.parking_spaces.every(c=>c.selected);
  }
  releasePSG(){
    var l = this.ps.groups;
    l.splice(l.indexOf(this), 1);
    this.ps.selected = this.ps.groups.some(c=>c.selected);
    this.ps = undefined;
  }
}
