<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-profiles-metered">
  <div class="box">
    <div class="header">
      <div class="text">{{$t('common.metered_hours_profiles')}}</div>
      <div @click="m.mhps.addP()" class="btn pushright"><i class="icon icon-plus"></i></div>
    </div>
  </div>
  <profile v-for="p in m.mhps.list" :owner="m.mhps" :p="p" :labels="$t('days_of_week')"></profile>
  <div class="box zones">
    <div class="header">
      <div class="text">{{$t('common.metered_hours_profiles')}} - {{$t('common.parking_zone')}} {{$t('common.assignment')}}</div>
    </div>
    <table>
      <thead>
        <tr>
          <th>{{$t('common.parking_zone')}}</th>
          <th>{{$t('common.profile')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="z in m.zones.list">
          <td @click="m.selection.selectList(z)" class="click">{{z.name}}</td>
          <td><v-select :options="m.mhps.list" :get-option-label="pp=>pp.name" label="name" :value="z.metered_hours_profile" 
            @input="m.zones.setMHP(z, $event)"><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.settings-profiles-metered{
  &>.box>.header>.text{
    font-size: 18px;
  }

  .zones{
    margin-top: 50px;
  }

  .zones>table{
    padding: 15px;
    width: 100%;

    th{
      text-align: left;
    }

    thead::after{
      content: "";
      display: block;
      height: 10px;
    }

    td{
      width: 50%;
    }
    .v-select .dropdown-toggle{
      background-color: #fff;
      border-radius: @border-radius;
    }
  }
}

</style>

<script>
import Profile from './profile.vue';
import m from '/model';
import VSelect from 'vue-select';

export default{
  name: 'settings-profiles-metered',
  components: { Profile, VSelect },
  data(){
    return{
      m
    };
  }
};
</script>
