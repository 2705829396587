/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


const messages = {
  en: {
    common: {
      setting: 'Setting',
      settings: 'Settings',
      edit: 'Edit',
      account: 'Account',
      profile: 'Profile',
      profiles: 'profiles',
      parking: 'Parking',
      parking_event: 'Parking Event',
      closure: 'Closure',
      closures: 'Closures',
      parking_space: 'Parking space',
      parking_spaces: 'Parking spaces',
      total_parking_spaces: 'parking spaces',
      parking_zone: 'Parking zone',
      parking_zones: 'Parking zones',
      parking_space_group: '@:common.parking_space group',
      parking_space_groups: '@:common.parking_space groups',
      parking_sensor: 'Parking sensor',
      parking_sensors: 'Parking sensors',

      metered_hours: 'Metered hours',
      metered_hours_profile: 'Metered hours profile',
      metered_hours_profiles: 'Metered hours profiles',
      metered_hours_detail: 'Metered hours detail',
      metered_hours_details: 'Metered hours details',

      loading_hours: 'Loading hours',
      loading_hours_profile: 'Loading hours profile',
      loading_hours_profiles: 'Loading hours profiles',
      loading_hours_detail: 'Loading hours detail',
      loading_hours_details: 'Loading hours details',

      residential_hours: 'Residential hours',
      residential_hours_profile: 'Residential hours profile',
      residential_hours_profiles: 'Residential hours profiles',
      residential_hours_detail: 'Residential hours detail',
      residential_hours_details: 'Residential hours details',

      group_alert_rule: 'Group alert rule',
      group_alert_rules: 'Group alerts rules',

      parking_space_parking_sensor: 'Sensor of parking space',
      details: 'Details',
      processing: 'Processing…',
      selected: 'selected',
      selection_empty: 'Sorry, no matching options',
      visible: 'visible',
      clear_selection: 'Clear selection',
      search_place: 'Search for places',
      search_empty: 'Clear search box',
      highlight: 'Jump to the parking space',
      name: 'Name',
      value: 'Value',
      assignment: 'assignment',
      app_init_error: 'An error occurred while starting the application',
      true: 'yes',
      false: 'no'
    },
    edit: {
      new1: 'Add new',
      new2: '',
      editor: 'editor',
      select: 'select',
      save: 'Save',
      delete: 'Delete',
      confirm_delete: 'Do you really want to delete the item?',
      errors: {
        ordering: 'Timeranges must start before ending',
        collision: 'Colliding timeranges'
      }
    },
    title: 'SmartParking System',
    loading: 'Loading...',
    menu: {
      companies: 'Companies',
      settings: {
        binding: '@:common.parking_space assignment'
      },
      account: {
        admin: 'Administration',
        logout: 'Sign out'
      },
    },
    settings: {
      day: 'Day',
      start: 'Start',
      end: 'End',
      binding: {
        name: '@:common.parking_space name'
      },
      spaceedit: {
        type: 'Type',
        layout: 'Layout',
        address: 'Address',
        location: 'Location',
        evcharge: 'Electric charging station',
        evonly: 'Reserved for electric vehicles',
        missing: 'is missing'
      },
      groupedit: {
        type: 'Type',
        tokens: 'Tokens',
        token: 'Token',
        validity: 'Validity',
      },
      closure: {
        start: 'Start',
        end: 'End',
        deactivate_sensors: 'Deactivate sensors',
        deactivate_sensors_hint: 'Sensors may need recalibration after a longer (> 1 week) deactivation',
        comment: 'Comment',
        status: 'Status',
        confirm_stop: 'Are you sure you want to stop the closure?'
      },
      gar: {
        enabled: 'Processing enabled',
        group: 'Observed group',
        recipients: 'Recipients',
        recipients_explain: 'Check box next to the name if the user should be notified',
        rule_eval_method: 'Evaluation method',
        sum_interval_minutes: 'Summarization of new parking attempts',
        threshold_percent: 'Threshold',
        threshold_explain: 'If a new parking attempt is detected on this amount of parking spaces in the observed group',
        percent: '%',
        last_alert_at: 'Last alert at',
        accepts_email: 'Email',
      }
    },
    account: {
      user: 'User',
      data: 'Data',
      email: 'Email',
      roles: 'Authorization level(s)',
      lang: 'Language',
      permissions: 'Permissions',
      create: 'Create',
      read: 'Read',
      update: 'Update',
      delete: 'Delete',
      password: 'Password',
      change: 'Change'
    },
    stats: {
      timeranges: {
        '24hours': '24 hours',
        'today': 'Today',
        'week': 'This week',
        'month': 'This month',
        'custom': 'Custom'
      },
      histranges: {
        'i': 'minutes',
        'h': 'hours',
        'd': 'days',
        'w': 'weeks',
        'm': 'months',
      },
      dividers: {
        'h': 'hours',
        'd': 'days',
        'w': 'weeks',
        'm': 'months',
        'y': 'years'
      },
      chartscales: {
        'linear': 'Linear view',
        'logarithmic': 'Logarithmic view'
      },
      charts: {
        avg_length: 'Average parking length',
        count: 'Parking event count',
        utilization: 'Average utilization',
        histogram: 'Parking time distribution'
      },
      mode: {
        paid: 'Metered timerange',
        free: 'Non-metered timerange',
        all: 'All time'
      },
      units: {
        min: 'min',
        pieces: 'pcs'
      },
      parkings: 'Parkings',
      current_states: 'Current States'
    },
    days_of_week: [
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sun',
      'Hol'
    ],
    pstypes: {
      N: 'Normal',
      R: 'Reserved for use by disabled',
      L: 'Loading zone'
    },
    pgtypes: {
      L: 'Location',
      S: 'Support',
      P: 'Private',
      D: 'Display',
      A: 'Attendant'
    },
    pslayouts: {
      L: 'Parallel',
      P: 'Perpendicular',
      F: 'Fishbone'
    },
    closurestatus: {
      U: 'Upcoming',
      I: 'In progress',
      P: 'Past',
      A: 'Aborted'
    },
    psstate: {
      0: 'Unknown',
      1: 'Free',
      2: 'Occupied',
      3: 'Closed off'
    },
    exports: {
      parkings: 'Parkings',
      charts: 'Charts'
    },
    gartypes: {
      DRI: 'Repeating interval',
      SLA: 'Since the last alert',
    },
    errormessages: {
      stat_timeout: 'Chart report request failed. Please select less parking spaces or shorten the timespan.',
      stat_overlap: 'Overlapping query aborted.',
      api_req_failed: 'Operation failed.<br>\nRequest from server: %%<br>\nMessage: '
    }
  },


  hu: {
    common: {
      setting: 'Beállítás',
      settings: 'Beállítások',
      edit: 'Szerkesztés',
      account: 'Felhasználó',
      profile: 'Profil',
      profiles: 'profilok',
      parking: 'Parkolás',
      parking_event: 'Parkolási Esemény',
      closure: 'Lezárás',
      closures: 'Lezárások',
      parking_space: 'Parkolóhely',
      parking_spaces: 'Parkolóhelyek',
      total_parking_spaces: 'parkolóhely',
      parking_zone: 'Parkoló zóna',
      parking_zones: 'Parkoló zónák',
      parking_space_group: '@:common.parking_space csoport',
      parking_space_groups: '@:common.parking_space csoportok',
      parking_sensor: 'Parkolószenzor',
      parking_sensors: 'Parkolószenzorok',

      metered_hours: 'Fizetős időszakok',
      metered_hours_profile: 'Fizetős időszak profil',
      metered_hours_profiles: 'Fizetős időszak profilok',
      metered_hours_detail: 'Fizetős időszak részlet',
      metered_hours_details: 'Fizetős időszak részletek',

      loading_hours: 'Rakodó időszakok',
      loading_hours_profile: 'Rakodó időszak profil',
      loading_hours_profiles: 'Rakodó időszak profilok',
      loading_hours_detail: 'Rakodó időszak részlet',
      loading_hours_details: 'Rakodó időszak részletek',

      residential_hours: 'Helyi lakosoknak fenntartott időszakok',
      residential_hours_profile: 'Helyi lakosoknak fenntartott időszak profil',
      residential_hours_profiles: 'Helyi lakosoknak fenntartott időszak profilok',
      residential_hours_detail: 'Helyi lakos időszak részlet',
      residential_hours_details: 'Helyi lakos időszak részletek',

      group_alert_rule: 'Csoport riasztás szabály',
      group_alert_rules: 'Csoport riasztás szabályok',

      parking_space_parking_sensor: 'Parkolóhely szenzora',
      details: 'Részletek',
      processing: 'Feldolgozás…',
      selected: 'kijelölve',
      selection_empty: 'Nem található a keresett elem',
      visible: 'látható',
      clear_selection: 'Kijelölés törlése',
      search_place: 'Címkeresés',
      search_empty: 'Keresés törlése',
      highlight: 'Ugrás a parkolóhelyhez',
      name: 'Név',
      value: 'Érték',
      assignment: 'hozzárendelés',
      app_init_error: 'Hiba lépett fel az alkalmazás indításakor',
      true: 'igen',
      false: 'nem'
    },
    edit: {
      new1: 'Új',
      new2: 'hozzáadása',
      editor: 'szerkesztő',
      select: 'kiválasztása',
      save: 'Mentés',
      delete: 'Törlés',
      confirm_delete: 'Biztosan törli az elemet?',
      errors: {
        ordering: 'Az időszak kezdetének meg kell előznie a végét',
        collision: 'Ütköző időszakok'
      }
    },
    title: 'Okos Parkolási Rendszer',
    loading: 'Betöltés...',
    menu: {
      companies: 'Vállalatok',
      settings: {
        binding: '@:common.parking_space összerendelés'
      },
      account: {
        admin: 'Adminisztráció',
        logout: 'Kijelentkezés'
      },
    },
    settings: {
      day: 'Nap',
      start: 'Kezdet',
      end: 'Vég',
      binding: {
        name: '@:common.parking_space neve'
      },
      spaceedit: {
        type: 'Típus',
        layout: 'Elrendezés',
        address: 'Cím',
        location: 'Lokáció',
        evcharge: 'Elektromos töltőállomás',
        evonly: 'Csak elektromos járműveknek',
        missing: 'hiányzik'
      },
      groupedit: {
        type: 'Típus',
        tokens: 'Aktiváló kódok',
        token: 'Aktiváló kód',
        validity: 'Érvényesség',
      },
      closure: {
        start: 'Kezdet',
        end: 'Vég',
        deactivate_sensors: 'Szenzorok deaktiválása',
        deactivate_sensors_hint: 'A szenzorokat lehet, hogy újra kell kalibrálni hosszabb (> 1 hét) deaktiválás után',
        comment: 'Megjegyzés',
        status: 'Státusz',
        confirm_stop: 'Biztosan azonnal leállítja a lezárást?'
      },
      gar: {
        enabled: 'Riasztás aktív',
        group: 'Megfigyelt csoport',
        recipients: 'Értesítettek',
        recipients_explain: 'Jelölje be a név melleti pipát az üzenetek küldéséhez',
        rule_eval_method: 'Megkezdett parkolások összesítése',
        sum_interval_minutes: 'Összesítési időszak',
        threshold_percent: 'Küszöbérték',
        // threshold_explain: 'A riasztáshoz a parkolóhelyek ekkora részén kell új parkolásnak indulni',
        threshold_explain: 'Ha a parkolóhelyek ekkora részén indul új parkolás',
        // threshold_explain: 'A parkolóhelyek ekkora részén kell új parkolásnak indulni',
        percent: '%',
        last_alert_at: 'Utolsó riasztás időpontja',
        accepts_email: 'Email',
      }
    },
    account: {
      user: 'Felhasználó',
      data: 'Adatok',
      email: 'E-mail',
      roles: 'Jogosultsági szint(ek)',
      lang: 'Nyelv',
      permissions: 'Hozzáférés',
      create: 'Létrehozás',
      read: 'Megtekintés',
      update: 'Frissítés',
      delete: 'Törlés',
      password: 'Jelszó',
      change: 'Megváltoztatása'
    },
    stats: {
      timeranges: {
        '24hours': '24 óra',
        'today': 'Nap',
        'week': 'Hét',
        'month': 'Hónap',
        'custom': 'Egyéni'
      },
      histranges: {
        'i': 'perc',
        'h': 'óra',
        'd': 'nap',
        'w': 'hét',
        'm': 'hó',
      },
      dividers: {
        'h': 'órák',
        'd': 'napok',
        'w': 'hetek',
        'm': 'hónapok',
        'y': 'évek'
      },
      chartscales: {
        'linear': 'Lineáris nézet',
        'logarithmic': 'Logaritmikus nézet'
      },
      charts: {
        avg_length: 'Átlagos parkolási idő',
        count: 'Parkolási események száma',
        utilization: 'Átlagos kihasználtság',
        histogram: 'Parkolási idők eloszlása'
      },
      mode: {
        paid: 'Fizetős időszak',
        free: 'Ingyenes időszak',
        all: 'Mindenkor'
      },
      units: {
        min: 'perc',
        pieces: 'db'
      },
      parkings: 'Parkolások',
      current_states: 'Jelenlegi állapot fennállása'
    },
    days_of_week: [
      'H',
      'K',
      'Sze',
      'Cs',
      'P',
      'Szo',
      'V',
      'Msz'
    ],
    pstypes: {
      N: 'Normál',
      R: 'Mozgáskorlátozott',
      L: 'Rakodó'
    },
    pgtypes: {
      L: 'Helyszín',
      // S: 'Üzemeltetés',
      // S: 'Support',
      S: 'Gyűjtő',
      P: 'Privát',
      D: 'Kijelző',
      A: 'Parkolóőr'
    },
    pslayouts: {
      L: 'Párhuzamos',
      P: 'Merőleges',
      F: 'Halszálka'
    },
    closurestatus: {
      U: 'Várakozik',
      I: 'Folyamatban',
      P: 'Lejárt',
      A: 'Megszakított'
    },
    psstate: {
      0: 'Ismeretlen',
      1: 'Szabad',
      2: 'Foglalt',
      3: 'Lezárt'
    },
    exports: {
      parkings: 'Parkolások',
      charts: 'Diagramok'
    },
    gartypes: {
      DRI: 'Ismétlődő fix intervallumban',
      SLA: 'Utolsó riasztás óta',
    },
    errormessages: {
      stat_timeout: 'Statisztika lekérdezés időtúllépés. Kérjük csökkentse a kijelölt parkolóhelyek számát vagy használjon rövidebb lekérdezési időszakot.',
      stat_overlap: 'Túl gyakori statisztika lekérdezések, csak a legutóbbi kérés kerül feldolgozása.',
      api_req_failed: 'Hiba történt a szerverrel való kommunikáció során.<br>\nMűvelet: %%<br>\nÜzenet: '
    }
  }
};

export default messages;
