<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="loading">
    <div class="title">{{$t('title')}}</div>
    <svg viewBox="-6 -6 160.023 209.578">
      <g>
        <path
          d=" M 132.065 1.086 C 107.609 6.151 88.143 28.927 88.217 54.742 C 88.217 55.606 88.29 56.521 88.389 56.941 C 129.322 66.921 147.899 91.625 148.023 125.789 C 148.121 159.979 121.145 197.429 75.543 197.578 C 38.612 197.704 0.174 166.18 0.001 115.538 C -0.123 82.065 17.96 49.827 41.033 30.706 C 63.563 12.08 94.417 0.099 122.381 0 C 125.988 0 129.768 0.296 132.065 1.086"
          class="background"
        />
        <path
          d=" M 132.065 1.086 C 107.609 6.151 88.143 28.927 88.217 54.742 C 88.217 55.606 88.29 56.521 88.389 56.941 C 129.322 66.921 147.899 91.625 148.023 125.789 C 148.121 159.979 121.145 197.429 75.543 197.578 C 38.612 197.704 0.174 166.18 0.001 115.538 C -0.123 82.065 17.96 49.827 41.033 30.706 C 63.563 12.08 94.417 0.099 122.381 0 C 125.988 0 129.768 0.296 132.065 1.086"
          class="progress"
        />
      </g>
    </svg>
    <div class="label">{{$t('loading')}} {{ progress }}%</div>
    <div class="progress-bar">
      <div class="progress-value" v-bind:style="{width: progress + '%'}"></div>
    </div>
  </div>
</template>

<style lang="less">
@import '/global';

@-webkit-keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
@-moz-keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
@-o-keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
@keyframes loading {
  0%   { stroke-dashoffset: 0; }
  100%   { stroke-dashoffset: 1218.5870361328125; }
}
.loading{
  width: 100%;
  height: 100%;
  padding-top: 5%;
  div{
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .title{
    margin-bottom: 120px;
    font-size: 34px;
  }
  .label{
    margin-top: 90px;
    font-size: 26px;
  }
  svg{
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    path{
      fill: none;
      stroke-width: 3;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
    }
    .background{
      stroke: #e3e3e3;
    }
    .progress{
      stroke: @color-accent;
      stroke-dasharray: 609.2935180664062 609.2935180664062;
      -webkit-animation: loading 3s ease-out infinite; /* Safari 4+ */
      -moz-animation:    loading 3s ease-out infinite; /* Fx 5+ */
      -o-animation:      loading 3s ease-out infinite; /* Opera 12+ */
      animation:         loading 3s ease-out infinite; /* IE 10+, Fx 29+ */
    }
  }
}
.progress-bar {
  position: relative;
  background-color: #e3e3e3;
  height: 8px;
  width: 160px;
  margin: 20px auto 0;
  .progress-value {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: red;
    transition: all 0.1s;
  }
}
</style>

<script>
export default {
  name: 'loading',
  props: ['progress'],
};
</script>
