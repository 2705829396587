/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/

export default function places(){
  var results = [];
  // var autocomplete = new google.maps.places.Autocomplete();

  async function search(input, lonLat, callback) {
    var acService = new google.maps.places.AutocompleteService();

    acService.getPlacePredictions({
      input,
      location: new google.maps.LatLng(lonLat[1], lonLat[0]),
      radius: 10000,
      componentRestrictions: { country: 'hu' },
      // types: ['address']
    }, callback);
  }
  async function getDetails(placeId, callback) {
    var placesService = new google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails({placeId}, callback);
  }
  return {
    list: results,
    search,
    getDetails,
  };
}
