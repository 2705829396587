<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="parkingspacegroup box">
  <div class="header" v-if="working && g">
    <div class="text">{{g.name}}</div>
    <div class="pushright"></div>
    <spinner></spinner>
  </div>
  <div class="header" v-if="!working">
    <div v-if="!edit" class="text">{{g.name}}</div>
    <input type="text" v-if="edit" v-model="g.name" :class="{error: errors.name}">
    <div class="pushright"></div>
    <div v-if="edit" @click="cancelEditing" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="edit" @click="finishEditing" class="btn"><i class="icon icon-check"></i></div>
    <div v-if="!anyEdit && allow.group_update && !edit && !deleting" @click="startEditing" class="btn"><i class="icon icon-edit"></i></div>
    <div v-if="deleting" @click="deleteGroup(g)" class="btn"><i class="icon icon-trashcan"></i></div>
    <div v-if="deleting" @click="deleting=false" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="!anyEdit && allow.group_delete && !deleting && !edit" @click="deleting=true" class="btn"><i class="icon icon-trashcan"></i></div>
  </div>
  <div class="edit">
    <div>{{$t('settings.groupedit.type')}}</div>
    <div v-if="!(edit && allow.group_update)"><div class="border">{{$t('pgtypes.'+g.type)}}</div></div>
    <div v-if="edit && allow.group_update"><v-select :clearable="false" :options="['L', 'S', 'P', 'D', 'A']" 
        :get-option-label="i=>$t('pgtypes.'+i)" v-model="g.type" :class="{error: errors.type}" :disabled="working"
        ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select></div>
  </div>
  <div class="edit" v-if="g.type == 'P' && edit && allow.group_create">
    <div>{{$t('settings.groupedit.tokens')}}</div>
    <div class="btn-menu">
      <div @click="g.newEmptyToken()" class="btn btn-block"><i class="icon icon-plus"></i></div>
    </div>
  </div>
  <grouptoken v-if="g.type == 'P' && !t.deleted" v-for="t in g.tokens" v-bind:key="t.id" :t="t" :owner="g" :showerrors="showerrors" :edit="edit && allow.group_update"></grouptoken>
  <div class="edit parking-spaces">
    <div>{{$t('common.parking_spaces')}}</div>
    <div class="border">
      <div class="space-value-header">
        <span class="value">{{getSetLength()}} {{getSetName()}}</span>
        <span class="action" @click="toggleParkingSpaces()" v-if="!showAll">
          <v-icon :name="isShowParkingSpaces ? 'chevron-down' : 'chevron-left'" />
        </span>
      </div>
      <div v-if="showAll || isShowParkingSpaces" class="space-value-content">
        <div v-if="!edit"><span v-for="psc in g.parking_spaces" v-bind:key="psc.id" class="item">{{psc.ps.name}}</span></div>
        <div v-if="edit" ><span v-for="ps in selectedpss" v-bind:key="ps.id" class="item">{{ps.name}}</span></div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.parkingspacegroup{
  position: relative;
  margin: 15px;

  .parking-spaces.edit {
    .item {
      padding: 2px 4px;
      background: #F0F0F0;
    }
  }

  .btn-block {
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    display: inline-block;
  }

  .btn-menu {
    text-align: right;
  }

  .error{
    outline: 2px solid red;
    outline-offset: -2px;
  }

  .header{
    padding-right: 15px;
    input{
      width: 250px;
      padding: 0 5px;
      font-size: 16px;
    }
  }

  .edit{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 15px 15px 30px 50px;

    .space-value-header {
      display: flex;
      justify-content: space-between;
      span {
        margin: 0;
        border: none;
        padding: 0;
        &.action {
          display: inline-block;
          color: red;
          font-weight: 500;
          cursor: pointer;
          padding: 0 8px;
          height: 16px;
        }
      }
    }
    .space-value-content {
      margin-top: 8px;
    }


    .item{
      display: inline-block;
      border: 1px solid rgba(60,60,60,.26);
      padding: 8px;
      margin: 0 4px 4px 0;
    }
    input {
      font-size: 16px;
      font-family: Vodafone;
      padding: 8px;
    }
    .border{
      border: 1px solid rgba(60,60,60,.26);
      padding: 8px;
    }
  }
  .v-select .dropdown-toggle  {
    background-color: #fff;
    border-radius: @border-radius;
  }
}
</style>

<script>
import VSelect from 'vue-select';
import m from '/model';
import Spinner from './spinner';
import grouptoken from './grouptoken.vue';

const validators = {
  name: i=>(i.length > 0),
  // tokens: ts=>ts.reduce((valid,t)=>t.validate() && valid, true)
};

export default {
  name: 'parkingspacegroup',
  props: {
    g: Object,
    showAll: Boolean,
    selectedpss: Array
  },
  components: {VSelect, grouptoken, Spinner},
  data(){
    return {
      allow: m.user.allow,
      deleting: false,
      working: false,
      editing: false,
      isShowParkingSpaces: false,
      showerrors: false,
      prevvals: {
        name: '',
        type: 'L',
        parking_spaces: [],
        tokens: []
      },
      errors: {
        name: false
      }
    };
  },
  destroyed() {
    if (this.editing && this.anyEdit) {
      m.groups.setEditMode(false);
    }
  },
  computed: {
    anyEdit(){
      return m.groups.editMode;
    },
    edit(){
      return this.g.isEmpty() || this.editing;
    }
  },
  methods: {
    deleteGroup: async function(group){
      const res = await m.groups.deleteG(group);
      this.deleting = !res;
    },
    startEditing(){
      m.selection.clear();
      m.selection.toggleList(this.g);
      this.prevvals.name = this.g.name;
      this.prevvals.type = this.g.type;
      this.prevvals.tokens = JSON.parse(JSON.stringify(this.g.tokens));
      this.editing = true;
      m.groups.setEditMode(true);
    },
    cancelEditing(){
      if(this.g.isEmpty()){
        m.groups.removeEmptyGroups();
      }else{
        this.g.name = this.prevvals.name;
        this.g.type = this.prevvals.type;
        this.g.tokens = [];
        for (let t of this.prevvals.tokens) {
          this.g.addToken(t);
        }
        this.editing = false;
      }
      m.groups.setEditMode(false);
    },
    toggleParkingSpaces() {
      this.isShowParkingSpaces = !this.isShowParkingSpaces;
    },
    getSetLength() {
      return this.edit
        ? this.selectedpss.length
        : (this.g && this.g.parking_spaces ? this.g.parking_spaces.length : 0);
    },
    getSetName() {
      return this.edit
        ? this.$t('common.selected')
        : this.$t('common.total_parking_spaces');
    },
    async finishEditing(){
      var err = false;
      for(var k in validators){
        if(!validators[k](this.g[k])){
          this.errors[k] = true;
          err = true;
        }else{
          this.errors[k] = false;
        }
      }
      if(err) {
        return;
      }

      this.working = true;
      this.showerrors = true;
      let res = await m.groups.saveG(this.g, this.selectedpss);
      this.working = false;
      if (res) {
        this.showerrors = false;
        this.editing = false;
        m.groups.setEditMode(false);
      }
    }
  }
};
</script>
