/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import moment from 'moment';

const ress = ['h', 'd', 'w', 'm'];

const configs = Object.freeze([
  {name: '24hours', start_end: ()=>{var m = moment().set({second:0,millisecond:0});return[m.clone().subtract(23, 'hours').startOf('hours').set({second:0,millisecond:0}), m];}, res: 'h'},
  {name: 'today', start_end: ()=>([moment().startOf('day'), moment().set({second:0,millisecond:0})]), res: 'h'},
  {name: 'week', start_end: ()=>([moment().startOf('isoweek'), moment().set({second:0,millisecond:0})]), res: 'd'},
  {name: 'month', start_end: ()=>([moment().startOf('month'), moment().set({second:0,millisecond:0})]), res: 'd'},
]);

export default function(model){
  function loadByName(name){
    var c = configs.find(c=>c.name==name);
    [o.start, o.end] = c.start_end().map((d)=>d.toDate());
    o.res = c.res;
    if(model.query)
      model.query.runFT();
  }
  const o = {
    list: configs.map(c=>c.name),
    resolutions: ress,
    start: undefined,
    end: undefined,
    res: undefined,
    loadByName
  };
  o.loadByName('24hours');
  return o;
};
