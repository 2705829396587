/********************************************************************************
 *                                                                              *
 * COPYRIGHT Cubilog 2022                                                       *
 *                                                                              *
 ********************************************************************************/


const classMap = {
  0: 'unknown', // 8
  1: 'free', // 16
  2: 'occupied', // 32
  3: 'closedoff' // 64
};

class GroupAlertRule{
  constructor(o){
    this.working = false;
    this.group = undefined;
    Object.assign(this, o);
  }
}

export default async function(model, data){
  var rules = [];
  var possibleRecipients = [];

  const baseURL = '/group-occupancy-alert/rules/'
  const getItemURL = (itemId) => baseURL + itemId + '/'

  function newFromJSON(json_data){
    var new_rule = new GroupAlertRule(json_data, model);
    new_rule.group = model.groups.getById(json_data.group);
    if (new_rule.last_alert_at) {
      new_rule.last_alert_at = new Date(new_rule.last_alert_at);
    }
    return new_rule;
  }

  if(!data){
    // Load rules from API
    var res = await model.api.get(baseURL);
    data = res.data

    // Load recipients from API
    const prURL = '/group-occupancy-alert/possible-recipients/';
    try {
      var res2 = await model.api.get(prURL);
      possibleRecipients = res2.data
    } catch(e) {
      console.log('Cannot get data from ' + prURL, e)
    }
  } 

  for(let gar of data){
    let new_rule = newFromJSON(gar);
    rules.push(new_rule);
  }

  return {
    rules, // array of GroupAlertRule
    possibleRecipients,
    async add(newvals){
      newvals.group = newvals.group.id;
      this.working = true;
      let error;
      await model.api.post(baseURL, newvals).then(res => {
        this.working = false;
        let new_rule = newFromJSON(res.data);
        rules.unshift(new_rule);
      }).catch(e => {
        this.working = false;
        error = e;
        console.log('GroupAlertRule save failed: ' + e)
      });
      return !error;
    },
    async delete(rule){
      let error;
      this.working = true;
      await model.api.delete(getItemURL(rule.id)).then(() => {
        this.working = false;
        rules.splice(rules.indexOf(rule), 1);
      }).catch(e => {
        this.working = false;
        error = e;
        console.log('GroupAlertRule delete failed: ' + e)
      });
      return !error;
    },
    async update(rule, newvals){
      newvals.group = newvals.group.id;

      // API-call
      this.working = true;
      let error = null;
      await model.api.patch(getItemURL(rule.id), newvals).then(res => {
        this.working = false;
        let data = res.data
        // General
        rule.name = data.name;
        rule.group = model.groups.getById(data.group);
        rule.enabled = data.enabled;
        rule.recipients = data.recipients;
        // Exec
        rule.rule_eval_method = data.rule_eval_method;
        rule.sum_interval_minutes = data.sum_interval_minutes;
        rule.threshold_percent = data.threshold_percent;
      }).catch(e => {
        this.working = false;
        error = e;
        console.log('GroupAlertRule update failed: ' + e)
      });
      return !error;
    }
  };
}
