/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import {ParkingSpaceList} from './parkingspaces';

class Zone extends ParkingSpaceList{
  constructor(o){
    super();
    Object.assign(this, o);
    this.parking_spaces = [];
  }
}
export default async function zones(model){
  var zones = [];
  var res = await model.api.get('/parkingzones/');
  res.data.forEach(z=>{
    var nz = new Zone(z);
    if(nz.metered_hours_profile !== null){
      nz.metered_hours_profile = model.mhps.getById(nz.metered_hours_profile);
    }
    zones.push(nz);
  });
  return {
    list: zones,
    getById(id){
      return zones.find(i=>(i.id==id));
    },
    async setMHP(z, mhp){
      if(z.metered_hours_profile !== mhp){
        await model.api.patch('/parkingzones/' + z.id + '/', {
          metered_hours_profile: mhp !== null ? mhp.id : null
        });
        z.metered_hours_profile = mhp;
      }
    }
  };
};
