<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-parkingspaces">
  <div class="box">
    <div class="header">
      <div class="text">{{$t('common.parking_spaces')}}</div>
      <div v-if="allow.ps_create && !adding" @click="adding=true" class="btn pushright"><i class="icon icon-plus"></i></div>
      <div v-if="adding" @click="adding=false" class="btn pushright"><i class="icon icon-cross"></i></div>
    </div>
  </div>
  <parkingspace v-if="adding" :adding="true" @finished="adding=false"></parkingspace>
  <parkingspace v-for="ps in selectedpss" v-bind:key="ps.id" :ps="ps"></parkingspace>
</div>
</template>

<script>
import m from '/model';
import parkingspace from './parkingspace.vue';

export default {
  name: 'settings-parkingspaces',
  components: { parkingspace },
  props: { selectedpss: Array },
  data(){
    return {
      allow: m.user.allow,
      adding: false
    };
  }
};
</script>
