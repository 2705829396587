/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import {use_v2_api, dev_sandbox_mode} from '../../config.yml';

import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import api from './api.js';
import user from './user.js';
import filters from './filters.js';
import charts from './charts.js';
import QTime from './qtime.js';
import companies from './companies.js';
import zones from './zones.js';
import groups from './groups.js';
import parkingspaces from './parkingspaces.js';
import parkingsensors from './parkingsensors.js';
import histogram from './histogram.js';
import closures from './closures.js';
import groupalertrules from './groupalertrules';

import selection from './selection.js';

import reports from './reports.js';
import query from './query.js';

import profiles from './profiles.js';
import places from './places';

class Model{
  constructor(){
    this.legacySelectionMode = true;

    this.selection = selection(this);

    this.filters = filters(this);
    this.charts = charts();
    this.qtime = QTime(this);
    this.histogram = histogram();
    this.places = places();

    this.settings = { 
      hideMap: false,
      hideSettings: false,
    }
    this.reports = reports();
    this.query = query(this);   

    this.loaded = false;
    this.error = false;
    this.progress = 0;
  }
  async load(){
    this.api = await api(this);

    Vue.directive('observe-visibility', ObserveVisibility);
    Vue.set(this, 'companies', await companies(this));
    this.progress = 10;
    try {
      await Promise.all([
        user(this)
          .then((user)=>{this.progress += 10; Vue.set(this, 'user', user)}),
        profiles('/meteredhoursprofiles/', '/meteredhoursdetails/', this)
          .then((mhps)=>{this.progress += 10; Vue.set(this, 'mhps', mhps)})
          .then(()=>zones(this))
          .then((zs)=>{this.progress += 5; Vue.set(this, 'zones', zs)}),
        profiles('/loadinghoursprofiles/', '/loadinghoursdetails/', this)
          .then((lhps)=>{this.progress += 5; Vue.set(this, 'lhps', lhps)}),
        profiles('/residentialhoursprofiles/', '/residentialhoursdetails/', this)
          .then((rhps)=>{this.progress += 5; Vue.set(this, 'rhps', rhps)}),
        groups(this)
          .then((gs)=>{this.progress += 10; Vue.set(this, 'groups', gs)}),
      ]);
      // Must succeed Group query
      if (this.user.allow.gar_read) {
        await Promise.all([
          groupalertrules(this)
            .then((gars)=>{this.progress += 5; Vue.set(this, 'groupalertrules', gars)}),
        ]);
      } else {
        Vue.set(this, 'groupalertrules', groupalertrules(this, []))
      }


      // Load parking sensor and parking space data parallelly
      let pageSize = 10000;
      let loaderFn = 'get';
      let sensorURL = '/parkingsensors/?page_size=' + pageSize;
      let spaceURL = '/parkingspaces/?page_size=' + pageSize;

      if (use_v2_api) {
        loaderFn = 'getall';
        sensorURL = '/v2/parkingsensors/?page_size=' + pageSize;
        spaceURL = '/v2/parkingspaces/?page_size=' + pageSize;
      }
      if (dev_sandbox_mode) {
        // Local Go service instance
        sensorURL = 'http://localhost:4080/api' + sensorURL
        spaceURL = 'http://localhost:4080/api' + spaceURL
      }

      let sensorData = [];
      let spaceData = [];
      await Promise.all([
        this.api[loaderFn](sensorURL)
          .then((res)=>{this.progress += 20; sensorData = res.data}),
        this.api[loaderFn](spaceURL)
          .then((res)=>{this.progress += 20; spaceData = res.data})
      ])
    
      // Assign in order, sensors first
      Vue.set(this, 'parkingsensors', await parkingsensors(this, sensorData))
      Vue.set(this, 'parkingspaces',  await parkingspaces( this, spaceData))
    } catch (e) {
      console.log('load error', e);
      this.error = true;
    }

    this.progress = 100;
    this.loaded = true;

    await closures(this)
      .then((lds)=>{Vue.set(this, 'closures', lds)})

  }
}

export default new Model();
