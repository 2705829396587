<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-closures" v-if="m.closures">
  <div class="box filter">
    <div class="filter-search">
      <div class="input">
        <i class="icon icon-search"></i>
        <input class="border" v-model="m.closures.data.search" v-on:change="handleFilterChange()"/>
      </div>
      <div class="input">
        <i class="icon icon-pin"></i>
        <v-select @input="handleSelectChange(d, $event)"
                  :options="m.parkingspaces.parking_spaces" label='name' :clearable="true"
        ><span slot="no-options">{{$t('common.selection_empty')}}</span></v-select>
      </div>
      <div class="input">
        <i class="icon icon-calendar"></i>
        <datetimepicker showreset="true" v-model="m.closures.data.date" v-on:input="handleFilterChange()" :datestringmode="true"></datetimepicker></div>
    </div>
    <div class="filter-state">
      <span class="item" v-for="f in m.closures.data.filters"
        :class="['btn', f.active ? 'active' : 'inactive', 'filter-' + f.value]"
        @click="toggleFilter(f)"
      >{{$t('closurestatus.' + f.value)}}</span>
    </div>
  </div>
  <div class="box">
    <div class="header">
      <div class="text">{{$t('common.closures')}}</div>
      <div v-if="allow.closure_create && !adding" @click="add()" class="btn pushright"><i class="icon icon-plus"></i></div>
      <div v-if="adding" @click="closeAdd()" class="btn pushright"><i class="icon icon-cross"></i></div>
    </div>
  </div>
  <closure v-if="adding" :adding="true" @finished="adding=false" :ld="{}" :selectedpss="selectedpss"></closure>
  <closure v-for="ld in m.closures.data.list" v-bind:key="ld.id" :ld="ld" :selectedpss="selectedpss"></closure>
  <pagination :records="m.closures.data.count" v-model="m.closures.data.page" :per-page="m.closures.data.pageSize" @paginate="handlePageClick"></pagination>
</div>
</template>

<style lang="less">
@import '/global';

.btn {
  &.inactive:hover,
  &.inactive {
      background-color: @color-base-bg-dark;
  }
}

.settings-closures .filter {
  margin-bottom: 10px;
  flex-direction: column;
}
.settings-closures .filter-state {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  padding: 1px 0;
  .item {
    margin: 4px;
  }
}

.settings-closures .filter-search {
  display: flex;
}

.input {
  display: flex;
  flex: 1 1 auto;
  max-width: calc(33.3333333333333% - 6px);
  margin: 4px;
  align-content: center;
  align-items: center;
  border: 1px solid #cdcdcd;
  background-color: #d8d8d8;
  padding: 4px 4px 4px 8px;
  box-sizing: border-box;
  i {
    margin-right: 8px;
    line-height: 32px;
  }
  .date-field {
    width: auto;
    flex: 1 1 auto;
    overflow: hidden;
  }
  input {
    width: auto;
    flex: 1 1 auto;
    border: 1px solid #cdcdcd;
    padding: 8px;
    height: 14px;
    overflow: hidden;
  }
  .v-select {
    max-width: calc(100% - 24px);
    flex: 1 1 auto;
  }
  .v-select .dropdown-toggle {
    background-color: white;
    border-radius: 0;
    border: 1px solid #cdcdcd;
    height: 32px;
    padding: 0;
  }
  .v-select .vs__selected-options {
    white-space: nowrap;
    overflow: hidden;
  }
  .v-select .vs__actions {
    padding: 0;
  }

  .reset {
    cursor: pointer;
    height: 32px;
    text-align: center;
    line-height: 32px;
    margin-left: 4px;
    background: grey;
    color: white;
    border: none;
    i {
      margin: 0;
    }
  }
}
.VuePagination {
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
  }
  li {
    padding: 0 12px;
    line-height: 40px;
    border: 1px solid transparent;
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        border-color: red;
      }
    }
    &.disabled a {
      color: #666;
    }
    &.active a {
      color: red;
    }
  }
  .VuePagination__count {
    display: none;
  }
}


</style>

<script>
import Vue from 'vue';
import m from '/model';
import VSelect from 'vue-select';
import Pagination from 'vue-pagination-2';
import closure from './closure.vue';
import datetimepicker from '../stats/datetimepicker.vue';

export default {
  name: 'settings-closures',
  props: { selectedpss: Array },
  components: {closure, datetimepicker, Pagination, VSelect},
  data(){
    return {
      m,
      allow: m.user.allow,
      adding: false,
    };
  },
  methods: {
    add(){
      this.adding = true;
      m.closures.setEditMode(true);
    },
    closeAdd(){
      this.adding = false;
      m.closures.setEditMode(false);
    },
    loadMore(){
      m.closures.loadMore();
    },
    toggleFilter(f){
      // Multiple selections
      let activeLen = m.closures.data.filters.filter(f=>f.active).length;
      if (!f.active || activeLen > 1) { // At least one filter must be selected
        f.active = !f.active;
        m.closures.reload();
      }
    },
    handleSelectChange(d, sensor) {
      m.closures.data.parking_spaces__id = sensor ? sensor.id : null;
      this.$forceUpdate();
      this.handleFilterChange();
    },
    handleFilterChange() {
      // console.log(m.closures);
      m.closures.reload();
    },
    handlePageClick(p) {
      m.closures.loadPage(p);
    }
  }
};
</script>
