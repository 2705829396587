/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/

import Vue from 'vue';
import translations from '../translations.js';
import {use_v2_api, dev_sandbox_mode} from '../../config.yml';

function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
}

export default function(model){
  const excelReportTemplate = require('../../lib/reports/vfsp-stats-report.xlsx');

  var visibleCharts = {
    utilization: false,
    count: false,
    avg_length: false
  };

  if (window.localStorage.appInstanceId == null) {
    window.localStorage.appInstanceId = Math.round(1000000 + Math.random() * 8999999);
  }

  var urlProvider = {
    getQueryUrl: function() {
      let source = use_v2_api ? '/v2/parkingquery/' : '/parkingquery/';
      if (dev_sandbox_mode) {
        source = 'http://localhost:4080/api' + source;
      }
      return source
    },
    getExportUrl: function() {
      let source = use_v2_api ? '/v2/parkings/xlsx/' : '/parkings/xlsx/';
      if (dev_sandbox_mode) {
        source = 'http://localhost:4080/api' + source;
      }
      return source 
    }
  }

  var result = {
    l0: undefined,
    l1: undefined,
    ph: undefined,    
    info: {
      l0Proc: false,
      l0HasData: false,
      histoHasData: false,
      l1Proc: false,
      l1HasData: false,
      phProc: false,
      phHasData: false,
    },
  };

  // This represents the amount of visible data.
  //   0: only the headers visible, it's enough to load only the whole range
  //   >: one of the charts is also visible, so we need to load the chart's data as well
  var level = 0;

  var paramsS = '';
  var paramsHist = '';
  var paramsFT = '';

  function serializeS(){
    paramsS = '';
    var params = new URLSearchParams();
    var s = new Set();

    model.zones.list.forEach(z=>{
      if(z.selected){
        params.append('pz', z.id);
        z.parking_spaces.forEach(c=>{
          s.add(c.ps);
        });
      }
    });
    model.groups.list.forEach(g=>{
      if(g.selected){
        params.append('pg', g.id);
        g.parking_spaces.forEach(c=>{
          s.add(c.ps);
        });
      }
    });
    model.parkingspaces.parking_spaces.forEach(ps=>{
      if(ps.selected && !s.has(ps)){
        params.append('ps', ps.id);
      }
    });

    paramsS = params.toString();
  }

  function serializeFT(){
    paramsFT = '';
    var params = new URLSearchParams();
    model.filters.forEach(filter=>{
      if (filter.active) {
        if (Array.isArray(filter.urlValue)) {
          for (let v of filter.urlValue) { 
            params.append(filter.urlParam, v);
          }
        } else {
          params.append(filter.urlParam, filter.urlValue);
        }
      }
    })
    params.set('start', model.qtime.start / 1000 | 0);
    params.set('end', model.qtime.end / 1000 | 0);
    params.set('now', Math.floor((new Date().getTime()) / 1000) | 0);
    paramsFT = params.toString();
  }

  function serializeHist(){
    paramsHist = '';
    var params = new URLSearchParams();
    if(model.histogram.enabled) {
      params.set('hist_range_size', model.histogram.select.hist_range_size);
    }
    paramsHist = params.toString();
  }


  async function queryPH(){
    var selectedpss = model.parkingspaces.parking_spaces.filter(ps=>ps.selected);
    // console.log('sel length', selectedpss.length, 'sel data', selectedpss); // log
    if(selectedpss.length == 1){
      var params = new URLSearchParams();
      params.append('ps', selectedpss[0].id);
      params.set('start', model.qtime.start / 1000 | 0);
      params.set('end', model.qtime.end / 1000 | 0);
      result.ph = undefined;
      result.info.phHasData = false;
      result.info.phProc = true;

      var r = [];
      try {
        r = await model.api.getall('/parkings/?' + params.toString());
      } finally {
        result.ph = r.map(d=>({
          ps: d.parking_space,
          start: new Date(d.start),
          end: new Date(d.end)
        }));
        result.info.phProc = false;
        result.info.phHasData = true;
      }
    } else {
      result.ph = undefined;
      result.info.phHasData = false;
    }
  }

  async function queryStats(){  
    result.info.l0Proc = true;
    result.info.l1Proc = true;
    result.l0 = undefined;
    result.l1 = undefined;
    result.info.l0HasData = false;
    result.info.l1HasData = false;
    result.info.histoHasData = false;

    var res = {};
    try {
      var params = paramsS + '&' + paramsFT + '&' + paramsHist
      if(level > 0){
        params += '&step=' + model.qtime.res.toUpperCase()
      }
      if (params.length < 250) {
        res = await model.api.get(urlProvider.getQueryUrl() + '?' + params);
      } else {
        res = await model.api.post(urlProvider.getQueryUrl(), params);
      }
    } catch(e) {
      result.info.l0Proc = false;
      result.info.l1Proc = false;
      return
    } finally {
      result.info.l0Proc = false;
      result.info.l1Proc = false;
    }

    if (typeof res.data.totals === 'object') {
      res.data.totals.params = res.data.params;
    } else {
      res.data.totals = {
        params: res.data.params
      };
    }
    result.l0 = res.data.totals;
    result.l1 = res.data.steps;
    result.info.l0HasData = !!result.l0;
    result.info.l1HasData = !!result.l1;
    result.info.histoHasData = result.info.l0HasData && !!result.l0.histogram;
  }

  function clearDatasets(){
    result.ph = undefined;
    result.l0 = undefined;
    result.l1 = undefined;
    result.info.l0HasData = false;
    result.info.histoHasData = false;
    result.info.l1HasData = false;
    result.info.phHasData = false;
  }
  
  var queryInProgress;
  async function query(){
    // console.log('qstart', paramsS); // log    
    if(paramsS == ''){
      clearDatasets();
      return;
    }

    // TODO: Do not run the query in editor mode (when the stats block is invisible)
    clearTimeout(queryInProgress);
    queryInProgress = setTimeout(function() { // Delay stat update
      Promise.all([queryPH(), queryStats()]);
    }, 850);
  }

  function run(){
    serializeS();
    serializeFT();
    serializeHist();
    // console.log('run'); // log
    query();
  }

  function runS(){
    serializeS();
    // console.log('runS'); // log
    query();
  }

  function runFT(){
    serializeFT();
    // console.log('runFT'); // log
    query();
  }

  function runHist(){
    serializeHist();
    if (paramsHist != '' && model.query.result.l0 && !model.query.result.l0.histogram) {
      // console.log('runHist'); // log
      query();
    }
  }

  function level_inc(){
    level += 1;
    if(level == 1 && paramsS != '')
      query();
  }
  function level_dec(){
    level -= 1;
  }

  async function downloadExcelReport(){
    class ExcelUpdate{
      constructor(o){
        this.action = 'set_array';
        this.sheet_by = 'name';
        this.sheet = 'SheetName';
        this.start_cell = 'A1';
        this.data = [];
        Object.assign(this, o);
      }
    }
    
    if(paramsS == ''){
      return;
    }

    var updates = [];

    const locale = window.localStorage.getItem('lang');
    var currentStates = model.parkingspaces.getSelectedByLastUpdated()
      .map(ps=>[ps.name, translations[locale].psstate[ps.state], ps.state_changed_at] // TODO: Since
    );
    updates.push(new ExcelUpdate({
      'sheet': 'Jelenlegi állapot fennállása',
      'start_cell': 'A9',
      'data': currentStates
    }));

    let data = model.reports.getStatsBlocks(result.l0, result.l1);

    updates.push(new ExcelUpdate({
      'sheet': 'Adat',
      'start_cell': 'A5',
      'data': data.params
    }));

    let row = 5;
    for (let summary of data.summaries) {
      updates.push(new ExcelUpdate({
        'sheet': 'Adat',
        'start_cell': 'F' + row,
        'data': summary
      }));
      row += 3;
    }

    if (model.histogram.enabled && data.histograms.length > 0) {
      let cols = ['J', 'L', 'N'];
      let i = 0;
      for (let histogram of data.histograms) {
        updates.push(new ExcelUpdate({
          'sheet': 'Adat',
          'start_cell': cols[i] + '5',
          'data': histogram
        }));
        i++;
      }
      updates.push(new ExcelUpdate({
        'sheet': 'Parkolási idők eloszlása',
        'action': 'chart_last_row_extend'
      }));
    } else {
      updates.push(new ExcelUpdate({
        'sheet': 'Parkolási idők eloszlása',
        'action': 'delete'
      }));
    }

    if (visibleCharts.utilization || visibleCharts.count || visibleCharts.avg_length) {
      updates.push(new ExcelUpdate({
        'sheet': 'Adat',
        'start_cell': 'Q5',
        'data': data.charts
      }));
    }
    if (visibleCharts.utilization) {
      updates.push(new ExcelUpdate({
        'sheet': 'Átlagos kihasználtság',
        'action': 'chart_last_row_extend'
      }));
    } else  {
      updates.push(new ExcelUpdate({
        'sheet': 'Átlagos kihasználtság',
        'action': 'delete'
      }));
    }
    if (visibleCharts.count) {
      updates.push(new ExcelUpdate({
        'sheet': 'Parkolási események száma',
        'action': 'chart_last_row_extend'
      }));
    } else  {
      updates.push(new ExcelUpdate({
        'sheet': 'Parkolási események száma',
        'action': 'delete'
      }));
    }
    if (visibleCharts.avg_length) {
      updates.push(new ExcelUpdate({
        'sheet': 'Átlagos parkolási idő',
        'action': 'chart_last_row_extend'
      }));
    } else {
      updates.push(new ExcelUpdate({
        'sheet': 'Átlagos parkolási idő',
        'action': 'delete'
      }));
    }

    // console.log('excel-updates', updates);

    const apiRoot = '';
    //const apiRoot = 'https://appserver.parkolas.vodafonecompass.hu';
    //const apiRoot = 'http://localhost:8092';
    let res = null;
    try {
      res = await model.api.post(
        apiRoot + '/reports/excel', 
        {
          inFile: window.location.protocol + '//' + window.location.hostname + excelReportTemplate,
          outFile: 'stats-report.xlsx',
          updates: updates
        },
        {responseType: 'blob'}
      );
    } catch(e) {
      Vue.toasted.error('stats-report.xlsx download failed', {icon: 'exclamation-triangle'}); // TODO: Translation
      return;
    }    
    saveFile(new window.Blob([res.data]), 'stats-report.xlsx');
  }

  async function exportxls(){
    if(paramsS == ''){
      return;
    }
    let res = null;
    try {
      res = await model.api.get(urlProvider.getExportUrl() + '?' + paramsS + '&' + paramsFT, {responseType: 'blob'});
    } catch(e) {
      Vue.toasted.error('export.xlsx download failed', {icon: 'exclamation-triangle'}); // TODO: Translation
      return;
    }
    if (res.data) {
      saveFile(new window.Blob([res.data]), 'export.xlsx');
    }
  }

  async function queryByPS(s, e){
    var params = new URLSearchParams();
    model.filters.forEach(filter=>{
      if (filter.active)
        params.append(filter.urlParam, filter.urlValue);
    })
    params.set('start', s / 1000 | 0);
    params.set('end', e / 1000 | 0);
    params.set('now', Math.floor((new Date().getTime()) / 1000) | 0);

    var res = await model.api.get(urlProvider.getQueryUrl() + '?' + paramsS + '&' + params.toString() + '&byps=true');
    return res.data.by_ps_totals ? res.data.by_ps_totals : res.data.totals;
  }

  return {
    run,
    runS,
    runFT,
    runHist,
    level_inc,
    level_dec,
    visibleCharts,
    result,
    exportxls,
    downloadExcelReport,
    queryByPS,
    queryPH,
    clearDatasets,
    config: urlProvider
  };
}
