<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="container">
    <div id="leaflet">

    </div>
    <div id="filter">
      <search-filter @highlightLocation="highlightLocation" :center="center"/>
    </div>
    <div id="viewmode" @click="settings.hideSettings = !settings.hideSettings">
      <div class="toggle-map">
        <i class="icon icon-chevron-left"  v-if=" settings.hideSettings"></i>
        <i class="icon icon-chevron-right" v-if="!settings.hideSettings"></i>
      </div>
    </div>
    <div id="clearsel" :title="$t('common.clear_selection')" @click="clearSelection()">
      <div class="count" v-if="selectedpss.length > 0">
        {{ selectedpss.length }} {{$t('common.selected')}}<span v-if="visibleselectedpss.length != selectedpss.length">, {{ visibleselectedpss.length }} {{$t('common.visible')}}</span>
      </div>

      <v-icon name="eraser"></v-icon>
    </div>
    <marker-tracker v-for="ps in pss" v-bind:key="ps.id" :ps="ps"></marker-tracker>
  </div>
</template>

<style lang="less">
@import (inline) "../../node_modules/leaflet/dist/leaflet.css";

@import '/global';

.container {
  position: relative;
  width: 100%;
  height: 100%;
}
#leaflet{
  height: 100%;
  width: 100%;
  z-index: 1;
  //.vendor(user-select, none);
}

#filter, #viewmode, #clearsel {
  position: absolute;
  z-index: 2;
  cursor: pointer;

  background-color: @color-accent;
  color: @color-accent-fg;
  border-radius: 15px;
  padding: 5px 10px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.8);
}

#filter{
  left: 10px;
  top: 10px;
}

#viewmode{
  right: 10px;
  top: 10px;

  .fa-icon{
    display: block;
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  &:hover{
    background-color: lighten(@color-accent, 10%);
  }
}

#clearsel{
  right: 60px;
  top: 10px;

  .count {
    float: left;
    margin-right: 10px
  }

  .fa-icon{
    display: block;
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  &:hover{
    background-color: lighten(@color-accent, 10%);
  }
}

.gps_ring {
  border: 2px solid #888;
  -webkit-border-radius: 50%;
  height: 20px;
  width: 20px;
  -webkit-animation: pulsate 0.8s ease-out;
  -webkit-animation-iteration-count: infinite;
  &.big {
    width: 80px;
    height: 80px;
    border-color: red;
  }
}

@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.5, 1.5); opacity: 0.0;}
}


.marker{
  background-size: cover;
  width: 10px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #000;

  &.unknown{background-color: @color-state-unknown;}
  &.occupied{background-color: @color-state-occupied;}
  &.free{background-color: @color-state-free;}
  &.closedoff{background-color: @color-state-closedoff;}

  &.selected{
    border: 3px solid #000;
  }
  &.highlight {
    animation: blinkingObject 0.15s 4;
  }
}

</style>

<script>

import Vue from 'vue';
import SearchFilter from '../filter/search.vue';

import model from '/model';
import moment from 'moment';

let mapRefreshQueue = [];
let mapRefreshTimer = null;

const MarkerTracker = {
  props: { ps: Object },
  computed: {
    visibles: function() { return [this.ps.selected, this.ps.lonLat, this.ps.state, this.ps.visible]; }
  },
  watch: {
    'visibles': function() { mapRefreshQueue.push(this.ps); }
  },
  render: () => null
}

Vue.component('marker-tracker', MarkerTracker);

let leafletMarkers = {};
let leafletMap;
let leafletHighlightLayer;
let leafletMarkersLayer;

const getColor = state => {
  switch (state) {
    case 0: return '#F2C618';
    case 1: return '#36B07F';
    case 2: return '#F52323';
    case 3: return '#AAAAAA';
  }
}

export default {
  name: 'm-map',
  props: ['selectedpss', 'visibleselectedpss', 'highlightedId'],
  components: {
    SearchFilter, MarkerTracker
  },
  data(){
    return {
      map: undefined,
      center: [model.companies.selected.latitude, model.companies.selected.longitude],
      pss: model.parkingspaces.parking_spaces,
      settings: model.settings,
    }
  },
  watch: {
    highlightedId: function(newVal) {
      if (newVal) {
        this.highlight(newVal);
      }
    },
    'settings.hideSettings': function() {
      leafletMap.invalidateSize();
    }
  },
  mounted(){
    // Watch performance benchmark
    // setInterval(()=>{
    //   this.pss[Math.ceil(Math.random() * this.pss.length - 1)].state = 
    //     Math.floor(Math.random() * 3);
    // }, 200);

    L.Map.BoxZoom.prototype._onMouseUp = function (e) {
      if ((e.which !== 1) && (e.button !== 1)) { return; }

      this._finish();

      if (!this._moved) { return; }
      // Postpone to next JS tick so internal click event handling
      // still see it as "moved".
      this._clearDeferredResetState();
      this._resetStateTimeout = setTimeout(L.Util.bind(this._resetState, this), 0);

      var bounds = new L.LatLngBounds(
          this._map.containerPointToLatLng(this._startPoint),
          this._map.containerPointToLatLng(this._point)
      );
      this._map.fire('selectionended', {boxZoomBounds: bounds})
    };

    leafletMap = L.map('leaflet', {
      center: this.center,
      zoom: Math.min(16, model.companies.selected.zoom_level),
      preferCanvas: true,
      zoomControl: false,
    });

    const map = leafletMap;
    let start = {x: 0, y: 0};
    map.on('movestart', ()=>{ start.x = null; start.y = null; });
    map.on('move', (e)=>{    
      if (e.sourceTarget && e.sourceTarget._newPos && e.sourceTarget._startPos) {
        if (start.x === null && start.y === null) {
          start.x = e.sourceTarget._startPos.x; start.y = e.sourceTarget._startPos.y;
        }
        const newpos = e.sourceTarget._newPos;
        const distance = Math.sqrt(Math.pow(newpos.x - start.x, 2) + Math.pow(newpos.y - start.y, 2));
        // console.log('move', distance);
        if (distance > 200) {
          // console.log('redraw after pan distance of', distance);
          map.setView(map.getCenter());
          start.x = newpos.x; start.y = newpos.y;
        }
      }
    });

    L.control.zoom({
      position: 'bottomright'
    }).addTo(leafletMap);


    L.tileLayer('https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=xEaKlRIAIaGA49OWKms6',{
      tileSize: 512,
      zoomOffset: -1,
      minZoom: 1,
      maxZoom: 20,
      crossOrigin: true,
    }).addTo(leafletMap);

    leafletHighlightLayer = L.layerGroup().addTo(leafletMap);
    leafletMarkersLayer = L.layerGroup().addTo(leafletMap);
    this.drawAll();

    model.parkingspaces.psUpdateCallback = () => {
      this.drawAll();
    };

    leafletMap.on("selectionended", (e) => {
      const boxContains = e.boxZoomBounds.contains;
      const psById = model.parkingspaces.getById;
      const mask = model.filter.mask;
      const parkingSpaces = Object.entries(leafletMarkers)
        .filter(([, marker]) => marker._map != null) // Visible
        .filter(([, marker]) => e.boxZoomBounds.contains(marker.getLatLng()))
        .map(([id]) => psById(id));
        // .filter(ps => ps.isVisible(mask));
      model.selection.selectAllParkingSpaces(parkingSpaces);
    });

    mapRefreshTimer = setInterval(()=>{
      if (mapRefreshQueue.length > 0) {
        for (let ps of mapRefreshQueue){
          this.renderMarker(ps);
        }
        mapRefreshQueue = [];
      }
    }, 80);
  },
  destroy() {
    clearInterval(mapRefreshTimer);
  },
  // beforeDestroy(){
  //   this.$root.$off('resized', this.resized);
  // },
  methods: {
    // resized(){
    //   this.map.resize();
    // },
    clearSelection() {
      return model.selection.clear();
    },
    drawAll() {
      leafletMarkersLayer.clearLayers();
      leafletMarkers = {};
      this.pss.forEach((ps) => {
        this.renderMarker(ps);
      });
    },
    getPs(ps) {
      return Object.freeze({
        id: ps.id,
        selected: ps.selected,
        lonLat: [ps.lonLat[0], ps.lonLat[1]],
        state: ps.state,
        visible: ps.isVisible(model.filter.mask)
      })
    },
    lazyGetTooltip(ps)  {
      const m = moment(new Date(ps.state_changed_at));
      return ps.name + ' – ' + this.$t('pstypes.'+ps.type) + ', ' + this.$t('pslayouts.'+ps.layout) + 
        (ps.electric_charging_station ? (', ' + this.$t('settings.spaceedit.evcharge')) : '') +
        '<br>' +
      this.$t('psstate.' + ps.state) + ' – ' + m.format('lll') + ' (' + m.fromNow() + ')';
    },
    markerClickHandler(e, ps) {
      if (model.parkingsensors.editMode) {
        return undefined;
      }
      if(!(e.originalEvent.ctrlKey || e.originalEvent.metaKey || e.originalEvent.shiftKey)) {
        model.selection.clear();
      }
      model.selection.togglePS(ps);
    },
    renderMarker(psObj) {
      const ps = this.getPs(psObj);
      let marker = leafletMarkers[ps.id];
      if (!marker) {
        marker = L.circleMarker([ps.lonLat[1], ps.lonLat[0]], {
          color: '#000000',
          weight: ps.selected ? 3 : 0.8,
          opacity: 1,
          fillColor: getColor(ps.state),
          fillOpacity: 1,
          radius: 6,
        });
        marker.bindTooltip(() => { return this.lazyGetTooltip(psObj) }, {offset: [10, 0]});
        marker.on('click', e => { this.markerClickHandler(e, psObj) } )
        leafletMarkers[ps.id] = marker;
      } else {
        marker.setStyle({
          weight: ps.selected ? 3 : 0.8,
          fillColor: getColor(ps.state),          
        });
        if (ps.selected) {
          marker.bringToFront();
        }
      }

      if (ps.visible) {
        if (marker._map == null) {
          marker.addTo(leafletMarkersLayer);
        }
      } else {
        if (marker._map != null) {
          marker.remove();
        }
      }
    },
    highlight(id) {
      leafletHighlightLayer.clearLayers();
      var ps = model.parkingspaces.getById(id);

      if (ps && ps.lonLat) {
        this.highlightLocation(ps.lonLat, true, 19);
      }
    },
    highlightLocation(lonLat, isSmall, minZoom) {
      minZoom = minZoom || 18;
      var zoom = leafletMap.getZoom() >= minZoom ? leafletMap.getZoom() : minZoom;
      this.center = lonLat;
      leafletMap.setView(new L.LatLng(lonLat[1], lonLat[0]), zoom, {
        animate: true,
        pan: {
          duration: 1
        }
      });

      var cssIcon = L.divIcon({
        className: 'css-icon',
        html: '<div class="gps_ring ' + (isSmall ? "small" : "big") + '"></div>',
        iconSize: [24,24]
      });
      L.marker([lonLat[1], lonLat[0]], {icon: cssIcon}).addTo(leafletHighlightLayer);

      setTimeout(() => {
        leafletHighlightLayer.clearLayers();
      }, 3000);
    }
  },
};
</script>
