<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="profile box">
  <div class="header" v-if="working && p">
    <div class="text">{{p.name}}</div>
    <div class="pushright"></div>
    <spinner></spinner>
  </div>
  <div class="header" v-if="!working">
    <input type="text" v-if="edit" v-model="newname">
    <div v-if="!edit" class="text">{{p.name}}</div>
    <div class="pushright"></div>
    <div v-if="!edit && !deleting" @click="startEditing" class="btn"><i class="icon icon-edit"></i></div>
    <div v-if="edit" @click="cancelEditing" class="btn"><i class="icon icon-cross"></i></div>
    <div v-if="edit" @click="finishEditing" class="btn"><i class="icon icon-check"></i></div>
    <div v-if="!edit && !deleting" @click="deleting = true" class="btn"><i class="icon icon-trashcan"></i></div>
    <div v-if="deleting" @click="owner.deleteP(p)" class="btn"><i class="icon icon-trashcan"></i></div>
    <div v-if="deleting" @click="deleting =false" class="btn"><i class="icon icon-cross"></i></div>
  </div>
  <div class="chartcontainer">
    <timetable :periods="periods" :selected="tbli" :edit-mode="edit"></timetable>
  </div>
  <div class="edit" v-if="edit && !working">
    <div class="error" v-if="error">{{$t('edit.errors.' + error)}}</div>
    <div class="btns">
      <div @click="add" class="btn"><i class="icon icon-plus"></i></div>
      <div @click="del" class="btn"><i class="icon icon-trashcan"></i></div>
    </div>
    <div class="flds">
      <div class="grid">
        <div class="tx">{{$t('settings.day')}}</div>
        <div class="dp"><daypicker v-model="p.details[tbli].day_of_week" :labels="labels"></daypicker></div>
        <div class="tx">{{$t('settings.start')}}</div>
        <div class="dp"><timepicker v-model="p.details[tbli].start"></timepicker></div>
        <div class="tx">{{$t('settings.end')}}</div>
        <div class="dp"><timepicker v-model="p.details[tbli].end"></timepicker></div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.profile{
  position: relative;
  margin: 15px;

  .header{
    input{
      width: 200px;
      padding: 0 5px;
      font-size: 16px;
    }
  }

  .chartcontainer{
    padding: 10px;
  }

  .edit{
    width: 100%;
    overflow: hidden;

    .error{
      float: left;
      width: calc(100% - 164px);
      margin-left: 82px;
      text-align: center;
      color: red;
      word-break: break-all;
      line-height: 30px;
    }

    .btns{
      display: flex;
      padding-right: 10px;
      float: right;
      .btn{
        margin-left: 10px;
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
      }
    }
    .flds{
      overflow: hidden;
      width: 500px;
      .grid{
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 2fr 1fr 2fr;
        grid-gap: 10px;
        width: 500px;
        margin: 40px 0;
      }
      .tx{
        height: 30px;
        line-height: 30px;
        text-align: right;
      }
      .dp>div{
        height: 26px;
        line-height: 26px;
      }
    }
  }
}
</style>

<script>
import Daypicker from './daypicker.vue';
import Timepicker from './timepicker.vue';
import Spinner from './spinner';

import m from '/model';
import Timetable from "./timetable";

function checkOrdering(ds){ // Check if non deleted details are chronologically correct, (p => q) == (!p || q)
  for(var i = 0; i < ds.length; i++){
    if(!ds[i].deleted && ds[i].start >= ds[i].end){
      // console.log(ds[i]);
      return false;
    }
  }
  return true;
}

function checkCollision(ds){ // Check if non deleted details are exclusive
  for(var i = 0; i < ds.length; i++){
    for(var j = 0; j < i; j++){
      if(!ds[i].deleted && !ds[j].deleted && ds[i].day_of_week == ds[j].day_of_week){
        if((ds[i].start <= ds[j].start && ds[i].end >= ds[j].start) || (ds[j].start <= ds[i].start && ds[j].end >= ds[i].start))
          return true;
      }
    }
  }
  return false;
}

export default{
  name: 'profile',
  components: { Timetable, Daypicker, Spinner, Timepicker },
  props: ['p', 'labels', 'owner'],
  data(){
    return{
      edit: false,
      working: false,
      deleting: false,
      newname: '',
      tbli: -1, // The currently selected bar, or -1 if there is no selected (it will be updated upon editing)
      hoverhelper: 0, // Equals 1 if mouse is over a bar
      error: undefined
    };
  },
  computed: {
    periods() {
      return this.p.details.filter(d=>!d.deleted).map(d=>({from: Math.max(0, d.start), to: Math.min(1439, d.end), day: d.day_of_week}));
    }
  },
  methods: {
    selectDetail(){
      this.p.details.some((d, i)=>{
        if(!d.deleted){
          this.tbli = i;
          return true;
        }
      }) || this.add();
    },
    startEditing(){
      this.newname = this.p.name;
      this.edit = true;
      this.selectDetail();
    },
    cancelEditing(){
      this.p.details.forEach(d=>{
        d.restore();
      });
      this.error = undefined;
      this.tbli = -1;
      this.edit = false;
    },
    finishEditing: async function(){
      if(!checkOrdering(this.p.details)){
        this.error = 'ordering';
        return;
      }
      if(checkCollision(this.p.details)){
        this.error = 'collision';
        return;
      }
      this.working = true;
      const res = await this.owner.saveP(this.p, this.newname);
      this.working = false;
      if (res) {
        this.error = undefined;
        this.tbli = -1;
        this.edit = false;
      }
    },
    selectPeriod(index) {
      if(this.edit) {
        if(index != this.tbli) this.tbli = index;
      }
    },
    add(){
      this.tbli = this.owner.addDetail(this.p);
    },
    del(){
      const res = this.owner.deleteDetail(this.p.details[this.tbli]);
      if (res) {
        this.selectDetail();
      }
    }
  }
};
</script>
