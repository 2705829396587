/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import car from '../../res/car.svg';
import loading from '../../res/loading.svg';
import wheelchair from '../../res/wheelchair.svg';
import icon_charging from '../../res/icon_charging.svg';
import icon_non_charging from '../../res/icon_non_charging.svg';

class Filter{
  constructor(icon, bit, model, urlParam, urlValue, divclass){
    this.icon = icon;
    this.v = 2**bit;
    this.model = model;
    this.urlParam = urlParam;
    this.urlValue = urlValue;
    this.divclass = divclass ? divclass : false;
  }
  get active(){
    return this.model.filter.mask & this.v;
  }
  set active(v){
    if(v)
      this.model.filter.mask |= this.v;
    else
      this.model.filter.mask &= ~this.v;

    this.model.query.runFT();
  }
}
export default function filters(model){
  const items = [
    new Filter({imgSrc:car,                   row:0 /*, title: this.$t('pstypes.N')*/}, 0, model, 'pstype', 'N'),
    new Filter({imgSrc:loading,               row:0 /*, title: this.$t('pstypes.L')*/}, 1, model, 'pstype', 'L'),
    new Filter({imgSrc:wheelchair,            row:0 /*, title: this.$t('pstypes.R')*/}, 2, model, 'pstype', 'R'),
    new Filter({imgSrc:icon_non_charging,     row:0 /*, title: this.$t('common.parking_space')*/},        7, model, 'psprop', 'notcharger'),
    new Filter({imgSrc:icon_charging,         row:0 /*, title: this.$t('settings.pageedit.evcharge')*/},  8, model, 'psprop', 'charger'),
    new Filter({divClass:'marker unknown',    row:1 /*, title: this.$t('psstate.0')*/}, 3, model, 'psstate', 0),
    new Filter({divClass:'marker free',       row:1 /*, title: this.$t('psstate.1')*/}, 4, model, 'psstate', 1),
    new Filter({divClass:'marker occupied',   row:1 /*, title: this.$t('psstate.2')*/}, 5, model, 'psstate', 2),
    new Filter({divClass:'marker closedoff',  row:1 /*, title: this.$t('psstate.3')*/}, 6, model, 'psstate', 3),
  ];
  model.filter = {
    mask: 2**items.length - 1
  }
  return items;
}
