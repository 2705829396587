/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


export default async function(model){
  var me;
  var [r1, r2] = await Promise.all([
    model.api.get('/me/'),
    model.api.get('/permissions/')
  ]);
  me = r1.data;
  me.permissions = new Set(r2.data.map(p=>p.name));
  me.allow = {
    pss_update: !!me.permissions.has('ParkingSensor.Update'),
    ps_update: !!me.permissions.has('ParkingSpace.Update'),
    ps_create: !!me.permissions.has('ParkingSpace.Create'),
    ps_delete: !!me.permissions.has('ParkingSpace.Delete'),
    group_update: !!me.permissions.has('ParkingSpaceGroup.Update'),
    group_create: !!me.permissions.has('ParkingSpaceGroup.Create'),
    group_delete: !!me.permissions.has('ParkingSpaceGroup.Delete'),
    grouptoken_delete: !!me.permissions.has('ParkingSpaceGroupToken.Delete'),
    closure_update: !!me.permissions.has('Closure.Update'),
    closure_create: !!me.permissions.has('Closure.Create'),
    closure_delete: !!me.permissions.has('Closure.Delete'),
    gar_read:   !!me.permissions.has('GroupOccupancyAlertRule.Read'),
    gar_update: !!me.permissions.has('GroupOccupancyAlertRule.Update'),
    gar_create: !!me.permissions.has('GroupOccupancyAlertRule.Create'),
    gar_delete: !!me.permissions.has('GroupOccupancyAlertRule.Delete')
  }
  return me;
};
