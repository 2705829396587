<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div>
  <div class="filter" v-for="row in [0, 1]" v-bind:key="row">
    <span 
      v-for="f in filters" v-bind:key="f.value" v-if="(f.icon.row || 0) == row" 
      :class="['btn', f.active ? 'active' : '', 'filter-' + f.v, 'has-min-height filter-' + f.v]" 
      @click="toggleFilter(f)"
      :style="[row > 0 ? {'margin-top': '0px'} : {}]">
      <img v-if="f.icon.imgSrc" :src=f.icon.imgSrc  :title="f.icon.title">
      <div v-if="f.icon.divClass" :class=f.icon.divClass :title="f.icon.title">
    </span>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.filter{
  flex: 0 0 auto;
  display: flex;
  padding: 1px 0;

  .spacer {
    flex-basis: 100%;
  }

  .filter-4{
    // Separator between parking space types and parking sensor states
    margin-right: 6px;
  }

  span.has-min-height {
    min-height: 30px;
  }
  span{
    flex: 1 1 auto;
    text-align: center;
    margin: 4px 2px;
    padding-top: 3px;

    div{
      position:relative;
      top: calc(50% - 10px);
      margin: 4px auto;
    }

    img{
      height:30px;
    }

  }
}
</style>

<script>
import model from '/model';

export default{
  name: 'leftmenu-filter',
  data(){
    return{
      filters: model.filters
    }
  },
  methods: {
    toggleFilter(f){
      f.active = !f.active;
    }
  }
};
</script>
