/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import {ParkingSpaceList} from './parkingspaces';
import C from './c.js';

class Group extends ParkingSpaceList{
  constructor(o){
    super();
    Object.assign(this, o);
    this.parking_spaces = [];
    this.tokens = [];
  }
  isEmpty(){
    return this.id == -1;
  }
  newEmptyToken(){
    var today = new Date();
    today.setHours(12);
    today = today.toISOString().substr(0, 10);

    return this.addToken({
      id: 0,
      valid_from: today,
      valid_to: today,
      group: this.id
    });
  }
  addToken(newvals){
    let t = new GroupToken({
      id: newvals.id,
      token: newvals.token,
      valid_from: newvals.valid_from,
      valid_to: newvals.valid_to,
      group: newvals.group,
      deleted: newvals.deleted
    });
    this.tokens.push(t);
    return t;
  }
}

class GroupToken{
  constructor(o){
    delete o.group;
    Object.assign(this, o);
    this.freeze();
    this.deleted = false;
  }
  freeze(){
    this._saved = Object.freeze({
      token: this.token,
      valid_from: this.valid_from,
      valid_to: this.valid_to
    });
  }
  restore(){ // Revert to the synced state, or delete if it does not exist
    if(this.id == 0){
      this.delete();
    }else{
      this.token = this._saved.token;
      this.valid_from = this._saved.valid_from;
      this.valid_to = this._saved.valid_to;
    }
  }
  isChanged(){
    return this.token != this._saved.token || 
      this.valid_from != this._saved.valid_from || 
      this.valid_to != this._saved.valid_to;
  }
  delete(){
    this.deleted = true;
    this.token = '';
    this.valid_from = null;
    this.valid_to = null;
  }
  getRaw(){
    return {
      token: this.token,
      valid_from: this.valid_from,
      valid_to: this.valid_to
    }
  }
  async save(api, groupid){
    if(!this.deleted){
      if(this.id == 0){
        await api.post('/parkingspacegrouptokens/', Object.assign(this.getRaw(), {group: groupid})).then(res=>{
          this.id = res.data.id;
          this.freeze();
        });
      }else if(this.isChanged()){
        await api.patch('/parkingspacegrouptokens/' + this.id + '/', Object.assign(this.getRaw(), {group: groupid})).then(res=>{
          this.id = res.data.id;
          this.token = res.data.token;
          this.valid_from = res.data.valid_from;
          this.valid_to = res.data.valid_to;
          this.freeze();
        });
      }
    }else if(this.id > 0){
      await api.delete('/parkingspacegrouptokens/' + this.id + '/').then(()=>{
        this.id = 0;
      });
    }
  }
}

export default async function groups(model){
  var groups = [];

  function getById(id){
    return groups.find(i=>(i.id==id));
  }

  let ts = await model.api.get('/parkingspacegrouptokens/');
  let gs = await model.api.get('/parkingspacegroups/');

  gs.data.forEach(g=>{
    groups.push(new Group(g));
  });

  ts.data.forEach(t=>{
    var g = getById(t.group);
    g.addToken(t);
  });

  async function saveTokens(g){
    for (let t of g.tokens) {
      await t.save(model.api, g.id);
    }
    for (let t of g.tokens) {
      if (t.deleted || t.id <= 0) {
        g.tokens.splice(g.tokens.indexOf(t), 1);
      }
    }
    return g.tokens;
  }
  function updatePSs(g, psids){
    // Remove pss
    g.parking_spaces = g.parking_spaces.filter(c=>{
      if(psids.indexOf(c.ps.id) < 0){
        c.releasePSG();
        return false;
      }
      return true;
    });
    // Add pss
    var gpsids = g.parking_spaces.map(c=>c.ps.id);
    psids.forEach(psid=>{
      if(gpsids.indexOf(psid) < 0){
        var ps = model.parkingspaces.getById(psid);
        ps.groups.push(new C(ps, g, true));
      }
    });
    // Update group selection
    g.selected = g.parking_spaces.length > 0 && g.parking_spaces.every(c=>c.selected);
  }

  return {
    list: groups,
    editMode: false,
    getById(id){
      return groups.find(i=>(i.id==id));
    },
    newEmptyGroup() {
      let g = new Group({id:-1,type:'L'});
      groups.unshift(g);
      this.setEditMode(true);
      return g;
    },
    hasEmptyGroup() {
      return this.list.filter(g=>g.id==-1).length > 0;
    },
    removeEmptyGroups() {
      this.list.filter(g=>g.id==-1).forEach(g=>this.removeG(g));
      this.setEditMode(false);
    },
    setEditMode(value) {
      this.editMode = value;
    },
    async removeG(g){
      groups.splice(groups.indexOf(g), 1);
    },
    async deleteG(g){
      let error;
      await model.api.delete('/parkingspacegroups/' + g.id + '/').then(() => {
        this.removeG(g);
        g.parking_spaces.forEach(c=>{
          c.releasePSG();
        });
      }).catch(e => {
        error = e;
      });
      return !error;
    },
    async saveG(g, selectedpss){
      const psids = selectedpss.map(ps=>ps.id);
      var payload = {
        name: g.name,
        type: g.type,
        parking_spaces: psids,
      }
      var res;
      let error;
      try {
        if(g.id > 0) {
          res = await model.api.patch('/parkingspacegroups/' + g.id + '/', payload);
        }else{
          payload.tokens = [];
          res = await model.api.post('/parkingspacegroups/', payload);
          g.id = res.data.id;
        }
      } catch (e) {
        error = e;
      }
      if (!error) {
        g.name = res.data.name;
        g.type = res.data.type;
        g.tokens = await saveTokens(g);
        updatePSs(g, psids);
      }
      return !error;
    }
  };
};
