/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import {urls} from '../../config.yml';
import C from './c.js';
import moment from 'moment';

const classMap = {
  0: 'unknown', // 8
  1: 'free', // 16
  2: 'occupied', // 32
  3: 'closedoff' // 64
};

const typeMap = {
  'N': 1,
  'L': 2,
  'R': 4
};

class ParkingSpace{
  constructor(o, model){
    Object.assign(this, o);
    this.lonLat = [this.longitude, this.latitude];
    delete this.longitude;
    delete this.latitude;
    // Model 
    this.filter = model.filter;
    this.working = false;
    this.selected = false;
    this.groups = [];
    this.parking_zone = undefined;
    this.highlight = false;
    this.renderInCurrStates = false;
  }
  isVisible(mask){
    return true &&
     (mask & typeMap[this.type]) &&   // Bit 0-2: Parking space type
     (mask & (2**this.state) << 3) && // Bit 3-6: Parking sensor
     (mask & (2**!this.electric_charging_station) << 6) &&  // Bit 7: Space is not charging station
     (mask & (2**this.electric_charging_station)  << 7); // Bit 8: Space is charging station
  }
  get visible(){
    return this.isVisible(this.filter.mask)
  }
  get class(){
    return classMap[this.state];
  }

  get state_changed_at(){
    return this._state_changed_at;
  }
  set state_changed_at(value){
    this._state_changed_at = value;
  }
}

export class ParkingSpaceList{
  constructor(){
    this.selected = false;
  }
}

function orderedList(list) {
  const locale = localStorage.getItem('lang') || 'en';
  return list.sort((a1, b1) => {
    let a = a1 ? a1.name : '';
    let b = b1 ? b1.name : '';
    return a.localeCompare(b, locale);
  });
}   

function orderedPsList(list) {
  const locale = localStorage.getItem('lang') || 'en';
  return list.sort((a1, b1) => {
    let a = a1 && a1.ps ? a1.ps.name : '';
    let b = b1 && b1.ps ? b1.ps.name : '';
    return a.localeCompare(b, locale);
  });
}   

export default async function(model, data){
  var parking_spaces = [];

  function getById(id){
    return parking_spaces.find(i=>(i.id==id));
  }

  function updateGs(ps, gids){
    // Remove groups
    ps.groups = ps.groups.filter(gc=>{
      if(gids.indexOf(gc.list.id) < 0){
        gc.releaseList();
        return false;
      }
      return true;
    });
    // Add groups
    var psgids = ps.groups.map(gc=>gc.list.id);
    gids.forEach(gid=>{
      if(psgids.indexOf(gid) < 0)
        ps.groups.push(new C(ps, model.groups.getById(gid)));
    });
    // Update PS selection
    ps.selected = ps.parking_zone.selected || ps.groups.some(gc=>gc.selected);
  }

  function loadPS(resdata){
    var nd = new ParkingSpace(resdata, model);

    nd.parking_zone = new C(nd, model.zones.getById(resdata.parking_zone));

    updateGs(nd, resdata.groups);

    nd.parking_sensor = model.parkingsensors.getById(nd.parking_sensor);
    model.parkingsensors.setAssigned(nd.parking_sensor, true);

    nd.loading_hours_profile     = model.lhps.getById(nd.loading_hours_profile);
    nd.residential_hours_profile = model.rhps.getById(nd.residential_hours_profile);

    nd.state_changed_at = new Date(nd.state_changed_at);

    parking_spaces.push(nd);

    return nd;
  }

  if(!data){
    // Load from API
    var res = await model.api.get('/parkingspaces/');
    data = res.data
  } 
  var list = orderedList(data);
  var l = data.length;
  for(var i = 0; i < l; i++){
    loadPS(list[i]);
  }



  var ws = new WebSocket('ws' + urls.backend.substr(4) + '/ws/notification/');
  ws.onmessage = function(event){
    var o = JSON.parse(event.data);
    var d = getById(o.parking_space_id);
    if(typeof d === 'undefined') return;
    d.state = o.state;
    d.state_changed_at = new Date();
    console.log('sensor' + d.id + 'changed by ws');
  }


  return {
    parking_spaces,
    highlightedId: null,
    psUpdateCallback: null,
    getById,
    getSelectedByLastUpdated(){ // Used only by Excel the source data builder, could be substituted
      return parking_spaces
        .filter(ps=>ps.selected && ps.visible)
        .sort((a, b)=>(a._state_changed_at - b._state_changed_at));
    },
    refreshMap() {
      if (typeof this.psUpdateCallback === 'function') {
        this.psUpdateCallback();
      }
    },
    async setSensor(ps, sensor){
      if(ps.parking_sensor.id != sensor.id){
        let error;
        await model.api.patch('/parkingspaces/' + ps.id + '/parkingsensor/', {
          parking_sensor: sensor.id
        }).then(res => {
          model.parkingsensors.setAssigned(ps.parking_sensor, false);
          model.parkingsensors.setAssigned(sensor, true);
          ps.parking_sensor = sensor;
        }).catch(e => {
          error = e;
        });
        return !error;
      }
    },
    async addPS(newvals){
      newvals.parking_zone = newvals.parking_zone.id;
      newvals.groups = newvals.groups.map(g=>g.id);
      newvals.loading_hours_profile     = newvals.type == 'L' ? newvals.loading_hours_profile.id : null;
      newvals.residential_hours_profile = newvals.residential_hours_profile ? newvals.residential_hours_profile.id : null;
      let error;
      await model.api.post('/parkingspaces/', newvals).then(res => {
        const ps = loadPS(res.data);
        this.reorderDependentLists(ps);
        this.refreshMap();
      }).catch(e => {
        error = e;
      });
      return !error;
    },
    async deletePS(ps){
      let error;
      await model.api.delete('/parkingspaces/' + ps.id + '/').then(res => {
        ps.parking_zone.releaseList();
        ps.groups.forEach(gc=>{
          gc.releaseList();
        });
        parking_spaces.splice(parking_spaces.indexOf(ps), 1);
        this.refreshMap();
      }).catch(e => {
        error = e;
      });
      return !error;
    },
    reorderDependentLists(ps){
      this.parking_spaces = orderedList(this.parking_spaces);
      ps.groups.forEach((g)=>{
        g.list.parking_spaces = orderedPsList(g.list.parking_spaces);
      });
      if (ps.parking_zone) {
        ps.parking_zone.list.parking_spaces = orderedPsList(ps.parking_zone.list.parking_spaces);
      }
    },
    async updatePS(ps, newvals){
      // Save parking_zone and inject its id to newvals to patch on backend
      var parking_zone = newvals.parking_zone;
      newvals.parking_zone = newvals.parking_zone.id;
      newvals.groups = newvals.groups.map(g=>g.id);

      var loading_hours_profile = newvals.loading_hours_profile;
      newvals.loading_hours_profile     = newvals.type == 'L' ? newvals.loading_hours_profile.id : null;
      var residential_hours_profile = newvals.residential_hours_profile;
      newvals.residential_hours_profile = newvals.residential_hours_profile ? newvals.residential_hours_profile.id : null;

      // API-call
      this.working = true;
      let error = null;
      await model.api.patch('/parkingspaces/' + ps.id + '/', newvals).then(res => {
        let data = res.data

        let oldName = ps.name;
        ps.name = data.name;
        this.working = false;
        // Maintain the double-linked bipartite graph of pakingspaces and lists
        ps.parking_zone.releaseList();
        ps.parking_zone.assignList(parking_zone);
        // End
        ps.type = data.type;
        ps.layout = data.layout;
        ps.address = data.address;
        ps.lonLat = [data.longitude, data.latitude];
        ps.electric_charging_station = data.electric_charging_station;
        ps.reserved_for_electric_vehicles = data.reserved_for_electric_vehicles;
        updateGs(ps, data.groups);
        ps.loading_hours_profile = loading_hours_profile;
        ps.residential_hours_profile = residential_hours_profile;
        if (oldName != ps.name) {
          this.reorderDependentLists(ps);
        }
        this.refreshMap();
      }).catch(e => {
        this.working = false;
        error = e;
      });
      return !error;
    }
  };
}
