<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="side-by-side" @mousemove="mousemove" @mouseup="mouseup" :class="{sizing: isResizing}">
  <div class="left" v-show="!close">
    <slot name="leftpane"></slot>
  </div>
  <div class="handle" v-if="!settings.hideSettings"  @mousedown="handleclicked" v-show="!close"></div>
  <div class="right"  v-if="!settings.hideSettings" :style="{width: close ? '100%' : lw}">
    <slot name="rightpane"></slot>
  </div>
</div>
</template>

<style lang="less">
@import '/global';

.side-by-side{
  display: flex;

  &.sizing{
    .vendor(user-select, none);
  }

  .left{
    flex: 1 1 auto;
    height: 100%;
  }
  .handle{
    flex: 0 0 8px;
    height: 100%;
    width: 8px;
    cursor: ew-resize;
    background-color: @color-border;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    z-index: 10;
  }
  .right{
    flex: 0 0 auto;
    height: 100%;
    overflow-y: hidden;
  }

}
</style>

<script>
import {throttle} from 'lodash';
import model from '/model';

export default {
  name: 'side-by-side',
  props: ['close'],
  data(){
    return {
      isResizing: false,
      lw: 675 + 'px',
      settings: model.settings,
    }
  },
  mounted(){
    this.deb_emit_resized = throttle(()=>{
      this.$root.$emit('resized');
    }, 100);
  },
  watch: {
    close(){
      // HACK™
      this.$nextTick(function(){
        this.$root.$emit('resized');
      });
    }
  },
  methods: {
    handleclicked(e){
      this.isResizing = true;
    },
    mousemove(e){
      if(!this.isResizing) return;

      var rect = this.$el.getBoundingClientRect();

      var leftW = rect.width - e.clientX + rect.left - 4;

      if(leftW > 675 && leftW < rect.width - 100){
        this.deb_emit_resized();
        this.lw = leftW + 'px';
      }
    },
    mouseup(e){
      this.isResizing = false;
      this.$root.$emit('resized');
    }
  }
};
</script>
