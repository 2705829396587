<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div id="current-states">
  <div class="label">{{$t('stats.current_states')}}</div>
  <div class="pss">
    <virtual-list
      class="virtual-list"
      :data-key="'id'"
      :data-sources="pss"
      :data-component="currentStateComponent"
    ></virtual-list>
  </div>
</div>
</template>
<style lang="less">
@import '/global';
#current-states{
  position: relative;
  margin: 15px;
  background-color: @color-base-bg;
  border: 1px solid @color-border;
  border-radius: @border-radius;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
  overflow: hidden;

  .label{
    font-size: 18px;
    padding: 10px;
    background-color: @color-border;
  }

  .pss{
    .psi{
      height: 20px;
      padding: 3px;
    }
  }
  .flip-list-move {
    transition: transform 1s;
    z-index: 100;
  }
  .virtual-list {
    max-height: 360px;
    padding: 15px;
    overflow: auto;
  }
}
</style>
<script>
import model from '/model';
import CurrentState from './current-state.vue';
import VirtualList from 'vue-virtual-scroll-list';

let screenRefreshQueue = [];
let screenRefreshTimer = null;

export default{
  name: 'current-states',
  props: { selectedpss: Array, visibleselectedpss: Array },
  components: {VirtualList},
  data() {
    return {
      currentStateComponent: CurrentState,
      dispRoot: document
    }
  },
  computed: {
    pss(){
      return this.visibleselectedpss.sort((a, b)=>(a._state_changed_at - b._state_changed_at)).map(e => ({
        id: e.id,
        name: e.name,
        class: e.class,
        _state_changed_at: e._state_changed_at,
      }));
    }
  },
  mounted(){
    this.dispRoot = document.getElementById('stats');
    screenRefreshTimer = setInterval(()=>{
      if (screenRefreshQueue.length > 0) {
        for (let update of screenRefreshQueue){
          update.ps.renderInCurrStates = update.visible;
        }
        screenRefreshQueue = [];
      }
    }, 80);
  },
  destroy() {
    clearInterval(screenRefreshTimer);
  },
  methods: {
    visibilityChanged (isVisible, entry, ps) {
      if (isVisible == true && ps.renderInCurrStates == false) {
        screenRefreshQueue.push({ps: ps, visible: true});
      }
      if (isVisible == false && ps.renderInCurrStates == true) {
        screenRefreshQueue.push({ps: ps, visible: false});
      }
    }
  },
};
</script>
