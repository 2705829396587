<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="locationpicker">
  <div class="picker-handle" :class="{disabled: disabled}" v-on:click="!disabled ? openPicker() : undefined;">
    <i class="icon icon-globe"></i>
  </div>
  <div class="overlay" v-if="open">
    <div class="overlay-bg" v-on:click="closePicker()"></div>
    <div class="overlay-box">
      <div class="header">
        <div class="label">
          {{this.name}}
          <span class="value">Lat: {{ lat }}, Lng: {{ lng }}</span>
        </div>
        <div class="actions">
          <i class="closebtn icon icon-check" v-on:click="savePosition()"></i>
          <i class="closebtn icon icon-cross" v-on:click="closePicker()"></i>
        </div>
      </div>
      <div class="map-container" id="picker-map"></div>
    </div>
  </div>
</div>
</template>

<style lang="less">
  @import '/global';

  .picker-handle {
    background: #e60000;
    color: white;
    height: 33px;
    line-height: 33px;
    width: 30px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #ff1a1a;
    }
    &.disabled {
      background: grey;
      cursor: default;
    }
  }
  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.1);
    z-index: 100000;
  }
  .overlay-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.2);
    z-index: 100001;
  }
  .overlay-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 100px;
    right: 100px;
    top: 100px;
    bottom: 100px;
    background: white;
    border: 2px solid white;
    z-index: 100002;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
    .header {
      display: flex;
      background: white;
      line-height: 30px;
      padding: 8px 16px;
      .label {
        flex: 1 1 auto;
        .value {
          display: inline-block;
          border-radius: 12px;
          line-height: 24px;
          background: #d4d4d4;
          padding: 0 10px;
          font-size: 12px;
          margin-left: 10px;
          font-weight: 500;
          vertical-align: middle;
        }
      }
      .actions {
        i {
          display: inline-block;
          width: 30px;
          height: 30px;
          background: #e60000;
          color: white;
          text-align: center;
          margin-left: 5px;
          cursor: pointer;
          &:hover {
            background-color: #ff1a1a;
          }
        }
      }
    }
    .map-container {
      flex: 1 1 auto;
    }
  }
</style>

<script>
import m from '/model';

export default {
  name: 'locationpicker',
  props: {
    name: String,
    disabled: Boolean,
    value: null,
  },

  data() {
    return {
      open: false,
      lat: Number(this.value && this.value[0] ? this.value[0] : 47.473934),
      lng: Number(this.value && this.value[1] ? this.value[1] : 19.070101),
      google: null,
      map: null
    }
  },

  watch: {
    value: function(newVal) {
      this.lat = Number(newVal && newVal[0] ? newVal[0] : 47.473934);
      this.lng = Number(newVal && newVal[1] ? newVal[1] : 19.070101);
    }
  },

  async mounted() {

  },

  methods: {
    openPicker() {
      this.open = true;
      setTimeout(() => {
        const map = new google.maps.Map(document.getElementById("picker-map"), {
          zoom: 18,
          center: { lat: this.lat, lng: this.lng },
          mapTypeId: "satellite",
        });
        const markers = new google.maps.Marker({
          position: { lat: this.lat, lng: this.lng },
          map: map,
          icon: {
            url: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
          },
          size: new google.maps.Size(20, 20),
        });
        const addLatLng = (event) => {
          this.lat = Number(event.latLng.lat().toFixed(6));
          this.lng = Number(event.latLng.lng().toFixed(6));
          markers.setPosition({ lat: this.lat, lng: this.lng })
        }
        map.addListener("click", addLatLng);
      });
    },
    closePicker() {
      this.open = false;
    },
    savePosition() {
      this.$emit('input', [this.lat.toString(), this.lng.toString()])
      this.closePicker();
    }
  }
}

</script>
