/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


class ParkingSensor{
  constructor(o){
    Object.assign(this, o);
  }
}

export default async function(model, data){
  const unassignedSensor = new ParkingSensor({
    name: '-',
    id: null
  });
  var parkingsensors = {
    null: unassignedSensor
  };
  var unassigned = new Set([unassignedSensor]);

  if(!data){
    // Load from API
    var res = await model.api.get('/parkingsensors/');
    data = res.data
  }  
  data.forEach((d)=>{
    const sensor = new ParkingSensor(d);
    parkingsensors[d.id] = sensor;
    unassigned.add(sensor);
  });
  parkingsensors = Object.freeze(parkingsensors);

  return {
    editMode: false,
    unassigned,
    setEditMode(value) {
      this.editMode = value;
    },
    getById(id){
      return parkingsensors[id];
    },
    setAssigned(sensor, assigned){
      if(sensor.id !== null){
        if(assigned)
          unassigned.delete(sensor);
        else
          unassigned.add(sensor);
      }
    }
  }
}
