<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div id="prkhstcntr">
  <div class="label">{{$t('stats.parkings')}} <div style="float:right" v-show="queryInfo.phProc">{{$t('common.processing')}}</div></div>
  <div ref="prkhst" id="prkhst"></div>  
</div>
</template>
<style lang="less">
@import '/global';
#prkhstcntr{
  position: relative;
  margin: 15px;
  background-color: @color-base-bg;
  border: 1px solid @color-border;
  border-radius: @border-radius;
  box-shadow: 0px 0px 8px rgba(27, 19, 19, 0.3);
  .label{
    font-size: 18px;
    padding: 10px;
    background-color: @color-border;
  }
  #prkhst{
    height: 50px;
    padding: 10px;
  }
}


// .svg-container {
//   display: inline-block;
//   position: relative;
//   width: 100%;
//   // padding-bottom: 100%;  aspect ratio
//   vertical-align: top;
// }
// .svg-content-responsive {
//   display: inline-block;
//   position: absolute;
//   top: 0;
//   left: 0;
// }
.cls{
  fill: #F2C618;
}
.prk{
  fill: red;
  // stroke-width: 0.5;
  // stroke: #fff;
}
</style>
<script>
import * as d3 from 'd3';
import moment from 'moment';

import model from '../model';

export default {
  name: 'parking-history',
  data(){
    return {
      queryInfo: model.query.result.info,
      qtime: model.qtime,
      width: 0
    }
  },
  mounted(){
    window.addEventListener('resize', this.resized);
    this.$root.$on('resized', this.resized);

    this.width = this.$refs.prkhst.clientWidth - 20;
    this.chart_init();
    this.scale_init();
    this.data_init();
  },
  beforeDestroy(){
    this.$root.$off('resized', this.resized);
    window.removeEventListener('resize', this.resized);
  },
  watch: {
    'qtime.start': function(){
      model.query.clearDatasets();
    },
    'qtime.end': function(){
      model.query.clearDatasets();
    },
    'queryInfo.phHasData': function(){
      this.data_init();
    }
  },
  methods: {
    resized(){
      // Reset zoom
      this.svg.call(this.zoom.transform, d3.zoomIdentity);
      this.width = this.$refs.prkhst.clientWidth - 20;
      this.chart_resize();
      this.scale_resize();
      this.data_resize();
    },
    chart_init(){
      this.x = d3.scaleTime();
      this.xAxis = d3.axisTop()
        // .tickFormat(d3.timeFormat("%m.%d. %H:%M"))
        .tickSize(-30);
      this.zoom = d3.zoom()
        .scaleExtent([1, 20])
        .on("zoom", this.zoomed);
      this.svg = d3.select('#prkhst')
        .append("svg")
        .attr("width", this.width)
        .attr("height", 50)
        .call(this.zoom);
      this.ax = this.svg.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0,15)");
      this.ad = this.svg.append('g');
    },
    chart_resize(){
      this.svg.attr("width", this.width);
    },
    scale_init(){
      this.x.domain([this.qtime.start, this.qtime.end]);
      this.x.range([0, this.width]);
      this.ax.call(this.xAxis.scale(this.x));
    },
    scale_resize(){
      this.x.range([0, this.width]);
      this.ax.call(this.xAxis.scale(this.x));
    },
    data_init(){
      //console.log('ph-data-update');
      this.ad.selectAll('rect').remove();
      this.ad.selectAll('rect')
        .data(model.query.result.ph)
        .enter()
        .append('rect')
        .attr('class', d=>(d.ps == -1 ? 'cls' : 'prk'))
        .attr('x', d=>(this.x(d.start)))
        .attr('y', 20)
        .attr('width', d=>(this.x(d.end) - this.x(d.start)))
        .attr('height', 20)
        .append('title')
        .text(function(d) {
          var m_start = moment(d.start);
          var m_end = moment(d.end);
          return m_start.format('lll') + ' – ' + m_end.format('lll') + 
            ', ~' + moment.duration(m_end.diff(m_start)).humanize(); 
        });
    },
    data_resize(){
      //console.log('ph-resize');
      this.ad.selectAll('rect')
        .attr('width', d=>(this.x(d.end) - this.x(d.start)))
        .attr('x', d=>(this.x(d.start)));
    },
    zoomed(){
      //console.log('ph-zoom');
      var zx = d3.event.transform.rescaleX(this.x);
      // this.scale_resize();
      // this.data_resize();
      this.ax.call(this.xAxis.scale(zx));
      this.ad.attr("transform", 'translate('+d3.event.transform.x+',0) scale('+d3.event.transform.k+',1)');
    }
  }
};
</script>
