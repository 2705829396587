export default function histogram(){
  var select = {
    hist_range_size: 14400,  // Seconds
    dimension: 'h', // Minute..month
    value: 4, // Dimension units
  };
  var enabled = false;

  var ranges = ['i', 'h', 'd', 'w', 'm'];
  var lengths = {
    'i':60, // 1 minute
    'h':3600, // 1 hour
    'd':86400, // 1 day
    'w':604800, // 7 days
    'm':2592000  // 30 days
  };

  function update(){
    if (select.value + 0 <= 0) {
      select.value = 10;
    }
    select.hist_range_size = lengths[select.dimension] * select.value;
  }

  return {
    select,
    enabled,

    ranges,
    lengths,
    update
  };
}
