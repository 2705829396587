<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="numpick" @mousewheel.prevent="scroll($event)">
    <div class="arrow up" @click="()=>{ if (!readonly) dec() }"></div>
    <input type="number" @input="inp" ref="input" v-bind:min="min" v-bind:max="max" v-bind:readonly="readonly">
    <div class="arrow down" @click="()=>{ if (!readonly) inc() }"></div>
  </div>
</template>

<style lang="less" scoped>
@import '/global';

.numpick{
  display: flex;
  flex-direction: column;
  margin-top: -42px;
  input{
    border: none;
    text-align: center;
    width: 50px;
    &:focus{
      outline: none;
    }
    &[type='number']{
      -moz-appearance:textfield;
    }
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
  }
  .arrow{
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 20px 15px;
    &.up{
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 7px solid #000;
    }
    &.down{
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 7px solid #000;
    }
  }
}
</style>

<script>
export default{
  name: 'numpicker',
  props: ['value', 'min', 'max', 'readonly'],
  data(){
    return {
      ival: parseInt(this.value),
      imin: parseInt(this.min),
      imax: parseInt(this.max)
    }
  },
  mounted(){
    this.update();
  },
  watch: {
    value(v){
      this.ival = parseInt(v);
      this.update();
    }
  },
  methods: {
    update(){
      this.$refs.input.value = ('0' + this.ival).slice(-2);
      this.$emit('input', this.ival);
    },
    scroll(e){
      if(e.wheelDelta > 0){
        if(this.ival > this.imin)
          this.ival--;
      }
      else{
        if(this.ival < this.imax)
          this.ival++;
      }
      this.update();
    },
    inp(e){
      if(Number.isNaN(e.srcElement.valueAsNumber)){
        e.srcElement.value = '00';
      }
      if(e.srcElement.value.length > 2){
        e.srcElement.value = e.srcElement.value.slice(-2);
      }
      if(e.srcElement.value.length < 2){
        e.srcElement.value = '0' + e.srcElement.value;
      }
      var v = e.srcElement.valueAsNumber;
      if(v < this.imin)
        v = this.imin;
      if(v > this.imax)
        v = this.imax;
      this.ival = v;
      this.update();
    },
    inc(){
      if(this.ival < this.imax)
        this.ival++;
      this.update();
    },
    dec(){
      if(this.ival > this.imin)
        this.ival--;
      this.update();
    }
  }
};
</script>
