<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="histogram">
  <div class="label">{{$t('stats.charts.histogram')}}<div style="float:right" v-show="queryInfo.l0Proc">{{$t('common.processing')}}</div></div>
  <div class="ov-c">
    <span :class="{'selected': pof == 1}" @click="clickOnPOF(1)"><div class="ov-c-l">{{$t('stats.mode.paid')}}</div></span>
    <span :class="{'selected': pof == 2}" @click="clickOnPOF(2)"><div class="ov-c-l">{{$t('stats.mode.free')}}</div></span>
    <span :class="{'selected': pof == 3}" @click="clickOnPOF(3)"><div class="ov-c-l">{{$t('stats.mode.all')}}</div></span>    
  </div>
  <transition name="ht">
    <div v-show="pof > -1" class="chartx">
      <div class="selector">
        <div class="scales">
          <v-select :options="chartScales" :get-option-label="o=>$t('stats.chartscales.'+o)" v-model="chartScale" :clearable="false" :searchable="false" ></v-select>
        </div>
        <div class="apply">
          <span class="btn active" @click="histogram.update()"><i class="icon icon-check"></i></span>
        </div>
        <div class="dim">
          <v-select :options="histogram.ranges" :get-option-label="o=>$t('stats.histranges.'+o)" v-model="histogram.select.dimension" :clearable="false" :searchable="false" ></v-select>
        </div>
        <div class="value">
          <input type="number" v-model="histogram.select.value" min="1" max="60">
        </div>
      </div>
      <canvas ref="rfchart" height="300" width="800" @click="clickOnBar" :style="{'cursor': hoverhelper ? 'pointer' : 'default'}"></canvas>
    </div>
  </transition>
</div>
</template>

<style lang="less">
@import '/global';

.histogram{
  position: relative;
  margin: 15px;
  background-color: @color-base-bg;
  border: 1px solid @color-border;
  border-radius: @border-radius;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
  overflow: hidden;

  .label{
    font-size: 18px;
    padding: 10px;
    background-color: @color-border;
  }

  .selector{
    margin: 0px 10px 10px;
    height: 34px;

    .scales{
      float: left;
      width: 200px;
    }

    .apply{
      float: right;
      width: 35px;

      .btn{
        text-align: center;
        padding: 4px;
        margin-left: 8px;
        border-radius: 4px;
        display: inline-block;

        i {
          font-size: 20px;
        }
      }
    }
    .value{
      float: right;
      width: 100px;

      input[type=number],input[type=text]{
        width: calc(100% - 32px);
        border-radius: 4px;
        border: 1px solid rgba(60,60,60,.26);
        padding: 8px;
      }
    }
    .dim {
      float: right;
      width: 120px;
    }
  }

  .ov-c{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px 10px;

    span{
      text-align: center;
      padding: 10px 0;
      border-radius: @border-radius;
      cursor: pointer;
      transition: background-color .5s ease-in-out;
      border: 1px solid @color-border;

      &.selected{
        background-color: @color-border;
      }

      .ov-c-l{
        //font-size: 12px;
        margin: 6px 0;
      }
      .ov-c-v{
        font-size: 20px;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .chartx{
    margin-bottom: 15px;
  }
}
</style>

<script>
import Chart from 'chart.js';
import moment from 'moment';
import VSelect from 'vue-select';

import model from '/model';

function hexToRGBA(h){
  h = h.trim();
  let r = 0, g = 0, b = 0;
  r = "0x" + h[1] + h[2];
  g = "0x" + h[3] + h[4];
  b = "0x" + h[5] + h[6];
  return "rgba("+ +r + "," + +g + "," + +b + ",1.0)";
}

function getLabelTransformer(hist_range_size, time_unit, time_unit_name) {
    // Return pre-configured transformer
    return function(key) {
      return Math.round(key / time_unit) + '–' + Math.round((key + hist_range_size) / time_unit) + ' ' + time_unit_name;
    }
}

const style = getComputedStyle(document.body);
const chart_color1 = style.getPropertyValue('--acc1');

export default {
  props: ['chart'],
  components: {VSelect},
  data(){
    return {
      queryInfo: model.query.result.info,
      histogram: model.histogram,
      pof: -1,
      bucket: undefined,
      chartScale: 'logarithmic',
      hoverhelper: 0, // Equals 1 if mouse is over a bar,
      chartScales: ['linear', 'logarithmic']
    };
  },
  mounted(){
    var ctx = this.$refs.rfchart.getContext('2d');
    this._chart = new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [{
          minBarLength: 6,
          data: [],
          // hoverBackgroundColor: hexToRGBA(chart_color2)
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            type: 'logarithmic',
            ticks: {
              suggestedMin: 0,
              callback: function(value) {
                return parseFloat(value);
              }                
            }
          }]
        },
        hover: {
          onHover: (e, el)=>{
            this.hoverhelper = el.length;
          }
        },
        tooltips: {
          callbacks: {           
            title: (tooltipItem)=>{ 
              var from = model.query.result.l0.histogram[this.bucket].labels[tooltipItem[0].index];
              return tooltipItem[0].label + (from == 0 ? '' : ',  ~' + moment.duration(from * 1000).humanize());
            }
          }
        }
      }
    });
  },
  methods: {
    updateChart(){
      var cd = this._chart.data
      if(this.pof > 0 && 
        typeof model.query.result.l0 !== 'undefined' && 
        typeof model.query.result.l0.histogram !== 'undefined'
      ){
        var labelTransformer = getLabelTransformer(
          model.query.result.l0.params.hist_range_size,
          model.histogram.lengths[model.histogram.select.dimension],
          this.$t('stats.histranges.'+model.histogram.select.dimension)
        );
        var h = model.query.result.l0.histogram;
        if(this.pof == 1)
          this.bucket = 'metered';
        else if(this.pof == 2)
          this.bucket = 'non_metered';
        else if(this.pof == 3)
          this.bucket = 'all';
        cd.labels = h[this.bucket].labels.map(labelTransformer);
        cd.datasets[0].data = h[this.bucket].values;
        cd.datasets[0].backgroundColor = Array(cd.datasets[0].data.length).fill(hexToRGBA(chart_color1));
      }else{
        cd.labels = [];
        cd.datasets[0].data = [];
      }
      this._chart.update();
    },
    clickOnPOF(pof){
      if(this.pof == pof){ // Close
        model.histogram.enabled = false;
        this.pof = -1;
      }else{
        this.pof = pof;
        this.updateChart();
        model.histogram.enabled = true;
        //model.query.runHist();
      }
    },
    clickOnBar(e){
      e;
    },
    translateUnit(unit){
      return this.$te('stats.units.' + unit) ? this.$t('stats.units.' + unit) : unit;
    },
  },
  watch: {
    chartScale: function(v) {
      // console.log('chart-scal-mod', v);
      this._chart.config.options.scales.yAxes[0].type = v;
      this.updateChart();
    },
    'histogram.select.hist_range_size': function(){
      if(model.query.result.l0)
        model.query.result.l0.histogram = undefined;
      model.query.runHist();
    },
    'histogram.enabled': function(){
      model.query.runHist();
    },
    'queryInfo.l0HasData': function(){
      this.updateChart();
    }
  }
}

</script>
