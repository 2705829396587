/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


export default function charts(){
  return [
    {
      unit: 'min',
      name: 'avg_length',
      transform(v){
        return Math.round(v / 60);
      },
      transformWithNulls(v){
        return v == 0 ? null : Math.round(v / 60);
      }
    },
    {
      unit: 'pieces',
      name: 'count',
      transform(v){
        return v;
      },
      transformWithNulls(v){
        return v == 0 ? null : v;
      }
    },
    {
      unit: '%',
      name: 'utilization',
      transform(v){
        return Math.round(v * 100);
      },
      transformWithNulls(v){
        return v == 0 ? null : Math.round(v * 100);
      }
    }
  ];
};
