<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Cubilog 2022                                                       |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-groupalertrules">
  <div class="box">
    <div class="header">
      <div class="text">{{$t('common.group_alert_rules')}}</div>
      <div v-if="allow.gar_create && !adding" @click="adding=true" class="btn pushright"><i class="icon icon-plus"></i></div>
      <div v-if="adding" @click="adding=false" class="btn pushright"><i class="icon icon-cross"></i></div>
    </div>
  </div>
  <groupalertrule v-if="adding" :adding="true" @finished="adding=false"></groupalertrule>
  <groupalertrule v-for="r in groupalertrules.rules" v-bind:key="r.id" :r="r"></groupalertrule>
</div>
</template>

<script>
import m from '/model';
import groupalertrule from './groupalertrule.vue';

export default {
  name: 'settings-groupalertrules',
  components: { groupalertrule },
  data(){
    return {
      allow: m.user.allow,
      adding: false,
      groupalertrules: m.groupalertrules,
    };
  }
};
</script>
