/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


class Company{
  constructor(o){
    Object.assign(this, o);
  }
}
export default async function companies(model){
  var res = await model.api.get('/companies/');
  var companies = res.data.map(c=>new Company(c));
  function getById(id){
    return companies.find(i=>(i.id==id));
  }
  var selected = getById(window.localStorage.getItem('co')) || companies[0];
  return {
    list: companies,
    selected,
    getById,
    select(id){
      window.localStorage.setItem('co', id);
      window.location.reload();
    }
  };
}
