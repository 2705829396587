<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Cubilog 2022                                                       |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="settings-profiles-residential">
  <div class="box">
    <div class="header">
      <div class="text">{{$t('common.residential_hours_profiles')}}</div>
      <div @click="m.rhps.addP()" class="btn pushright"><i class="icon icon-plus"></i></div>
    </div>
  </div>
  <profile v-for="p in m.rhps.list" :owner="m.rhps" :p="p" :labels="$t('days_of_week')"></profile>
</div>
</template>

<style lang="less">
@import '/global';

.settings-profiles-residential{
  &>.box>.header>.text{
    font-size: 18px;
  }
}

</style>

<script>
import Profile from './profile.vue';
import m from '/model';
import VSelect from 'vue-select';

export default{
  name: 'settings-profiles-residential',
  components: { Profile, VSelect },
  data(){
    return{
      m
    };
  }
};
</script>
