<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="chart">
  <div class="label">{{$t('stats.charts.' + chart.name)}}<div style="float:right" v-show="queryInfo.l0Proc">{{$t('common.processing')}}</div></div>
  <div class="ov-c">
    <span :class="{'selected': pof == 1}" @click="clickOnPOF(1)"><div class="ov-c-l">{{$t('stats.mode.paid')}}</div><div class="ov-c-v">{{paid}} {{translateUnit(chart.unit)}}</div></span>
    <span :class="{'selected': pof == 2}" @click="clickOnPOF(2)"><div class="ov-c-l">{{$t('stats.mode.free')}}</div><div class="ov-c-v">{{free}} {{translateUnit(chart.unit)}}</div></span>
    <span :class="{'selected': pof == 3}" @click="clickOnPOF(3)"><div class="ov-c-l">{{$t('stats.mode.all')}}</div><div class="ov-c-v">{{all}} {{translateUnit(chart.unit)}}</div></span>    
  </div>
  <transition name="ht">
    <div v-show="pof > -1" class="chartx">
      <canvas ref="rfchart" height="300" width="800" @click="clickOnBar" :style="{'cursor': hoverhelper ? 'pointer' : 'default'}"></canvas>
    </div>
  </transition>
  <transition name="ht">
    <div v-if="tbli > -1">
      <table>
        <thead>
          <tr>
            <th>{{$t('common.name')}}</th>
            <th>{{$t('common.value')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in tbld" v-if="d.v > 0">
            <td>{{d.name}}</td>
            <td>{{d.v}} {{translateUnit(chart.unit)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </transition>
</div>
</template>

<style lang="less">
@import '/global';

.chart{
  position: relative;
  margin: 15px;
  background-color: @color-base-bg;
  border: 1px solid @color-border;
  border-radius: @border-radius;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
  overflow: hidden;

  .label{
    font-size: 18px;
    padding: 10px;
    background-color: @color-border;
  }

  .ov-c{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px 10px;

    span{
      text-align: center;
      padding: 10px 0;
      border-radius: @border-radius;
      cursor: pointer;
      transition: background-color .5s ease-in-out;
      border: 1px solid @color-border;

      &.selected{
        background-color: @color-border;
      }

      .ov-c-l{
        font-size: 12px;
      }
      .ov-c-v{
        font-size: 20px;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .chartx{
    margin-bottom: 15px;
  }

  .ht-enter-active, .ht-leave-active{
    transition: max-height .5s ease-in-out;
    overflow-y: hidden;
  }
  .ht-enter, .ht-leave-to{
    max-height: 0px;
  }
  .ht-enter-to, .ht-leave{
    max-height: 800px;
  }

  table{
    border-collapse: collapse;
    width: calc(100% - 20px);
    margin: 0 10px 15px 10px;

    th{
      text-align: left;
    }

    th, td{
      border-bottom: 1px solid @color-border;
      padding: 5px 0;
    }
    tr:last-child td{
      border-bottom: none;
    }
  }
}
</style>

<script>
import Chart from 'chart.js';
import moment from 'moment';

import model from '/model';

function hexToRGBA(h){
  h = h.trim();
  let r = 0, g = 0, b = 0;
  r = "0x" + h[1] + h[2];
  g = "0x" + h[3] + h[4];
  b = "0x" + h[5] + h[6];
  return "rgba("+ +r + "," + +g + "," + +b + ",1.0)";
}

const style = getComputedStyle(document.body);
const chart_color1 = style.getPropertyValue('--acc1');
const chart_color2 = style.getPropertyValue('--acc2');

export default {
  props: ['chart'],
  data(){
    return {
      queryInfo: model.query.result.info,
      pof: -1,
      tbli: -1,
      hoverhelper: 0, // Equals 1 if mouse is over a bar,
      tbld: []
    };
  },
  mounted(){
    var ctx = this.$refs.rfchart.getContext('2d');
    this._chart = new Chart(ctx, {
      type: 'bar',      
      data: {
        datasets: [{
          minBarLength: 3,
          data: [],
          // hoverBackgroundColor: hexToRGBA(chart_color2)
          borderWidth: 0
        }]
      },
      options: {
        // animation: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                  suggestedMin: 0
                }
            }]
        },
        hover: {
          onHover: (e, el)=>{
            this.hoverhelper = el.length;
          }
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem, data)=>{
              // console.log(model.query.result.l1);
              // console.log(tooltipItem[0]);
              var s = moment(model.query.result.l1[tooltipItem[0].index].start);
              var e = moment(model.query.result.l1[tooltipItem[0].index].end);
              return [s.format('LLLL'), e.format('LLLL')];
            },
            label: (tooltipItem, data)=>{
              return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' ' + this.translateUnit(this.chart.unit);
            }
          }
        }
      }
    });
  },
  methods: {
    updateChart(){
      if(typeof model.query.result.l1 !== 'undefined'){
        const l1 = model.query.result.l1;
        this._chart.data.labels = l1.map(d=>d.label);
        if(this.pof == 1)
          this._chart.data.datasets[0].data = l1.map((d)=>(this.chart.transformWithNulls(d[this.chart.name].metered)));
        if(this.pof == 2)
          this._chart.data.datasets[0].data = l1.map((d)=>(this.chart.transformWithNulls(d[this.chart.name].non_metered)));
        if(this.pof == 3)
          this._chart.data.datasets[0].data = l1.map((d)=>(this.chart.transformWithNulls(d[this.chart.name].all)));
        this._chart.data.datasets[0].backgroundColor = Array(this._chart.data.datasets[0].data.length).fill(hexToRGBA(chart_color1));
        //console.log(hexToRGBA(chart_color1));
      }else{
        this._chart.data.labels = [];
        this._chart.data.datasets[0].data = [];
      }
      this._chart.update();
    },

    clickOnPOF(pof){
      if(this.pof == -1){ // Just opened one
        model.query.level_inc();
      }

      if(this.pof == pof){ // Close
        this.pof = -1;
        model.query.level_dec();
      }else{
        this.pof = pof;
        this.updateChart();
      }
      this.tbli = -1;
      this.tbld = [];
      
      if (this.pof > -1) {
        model.query.visibleCharts[this.chart.name] = true;
      } else {
        model.query.visibleCharts[this.chart.name] = false;
      }
    },
    clickOnBar(e){
      if(this.tbli == -1){ // Just opened one
        // model.query.level_inc();
      }

      var i = this._chart.getElementAtEvent(e)[0]._index;
      if(this.tbli == i){
        this.tbli = -1;
        // model.query.level_dec();
      }else{
        this.tbli = i;
        this.updateTbld();
      }
    },
    translateUnit(unit){
      return this.$te('stats.units.' + unit) ? this.$t('stats.units.' + unit) : unit;
    },
    async updateTbld(){
      var c = model.query.result.l1[this.tbli];
      var res = await model.query.queryByPS(new Date(c.start), new Date(c.end));
      delete res['params'];
      res = Object.entries(res).map(e=>({ps: model.parkingspaces.getById(e[0]), stat: e[1][this.chart.name]}));
      // console.log(res);
      if(this.pof == 1)
        this.tbld = res.map(d=>({name: d.ps.name, v: this.chart.transform(d.stat.metered)}));
      if(this.pof == 2)
        this.tbld = res.map(d=>({name: d.ps.name, v: this.chart.transform(d.stat.non_metered)}));
      if(this.pof == 3)
        this.tbld = res.map(d=>({name: d.ps.name, v: this.chart.transform(d.stat.all)}));
    }
  },
  watch: {
    // pof(v){
    //   if(v > -1){
    //     // This magic is needed because chartjs does not render the canvas when the canvas area 0.
    //     // So after the animation started, it tells chartjs to recalculate its size.
    //     // Now it is bigger then 0, so it continues to work responsively, as before it was collapsed.
    //     setTimeout(()=>{
    //       this._chart.resize();
    //     }, 100);
    //   }
    // },
    tbli(){
        var l = this._chart.data.datasets[0].data.length;
        var c = Array(l).fill(hexToRGBA(chart_color1));
        if(this.tbli > -1)
          c[this.tbli] = hexToRGBA(chart_color2);
        this._chart.data.datasets[0].backgroundColor = c;
        this._chart.update();
    },
    'queryInfo.l1HasData': function(){
      this.tbli = -1;
      this.tbld = [];
      this.updateChart();
    }
  },
  computed: {
    paid(){
      if(model.query.result.l0 && model.query.result.l0[this.chart.name])
        return this.chart.transform(model.query.result.l0[this.chart.name].metered);
      else
        return ' ';
    },
    free(){
      if(model.query.result.l0 && model.query.result.l0[this.chart.name])
        return this.chart.transform(model.query.result.l0[this.chart.name].non_metered);
      else
        return ' ';
    },
    all(){
      if(model.query.result.l0 && model.query.result.l0[this.chart.name])
        return this.chart.transform(model.query.result.l0[this.chart.name].all);
      else
        return ' ';
    }
  }
}

</script>
