<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
  <div class="timepicker">
    <numpick v-model="th" min="0" max="24"></numpick>
    <div class="colon">:</div>
    <numpick v-if="th  < 24" v-model="tm" min="0" max="59"></numpick>
    <numpick v-if="th == 24" v-model="zero" readonly="true" min="0" max="59"></numpick>
  </div>
</template>

<style lang="less" scoped>
@import '/global';

.timepicker{
  display: flex;
  .colon{
    flex: 0 0 2%;
  }
  .arrow{
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 20px 15px;
    &.up{
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 7px solid #000;
    }
    &.down{
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 7px solid #000;
    }
  }  
}
</style>

<script>
import numpick from './numpick.vue';

export default{
  name: 'timepicker',
  props: ['value'],
  components: {numpick},
  computed: {
    zero: {
      get(){ return 0; },
      set(){}
    },
    th: {
      get(){
        return this.value >= 1439 ? 24 : Math.floor(this.value / 60);
      },
      set(v){
        this.$emit('input', v * 60 + this.tm);
      }
    },
    tm: {
      get(){
        return this.value >= 1439 ? 0 : this.value % 60;
      },
      set(v){
        this.$emit('input', this.th * 60 + v);
      }
    }
  }
};
</script>
