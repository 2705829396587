/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


export default function(model){
  const events = {};

  function clear(){
    model.zones.list.forEach(z=>{
      if(z.selected){
        z.selected = false;
      }
    });
    model.groups.list.forEach(g=>{
      if(g.selected){
        g.selected = false;
      }
    });
    model.parkingspaces.parking_spaces.forEach(ps=>{
      if (ps.selected) {
        ps.selected = false;
      }
      if(ps.parking_zone.selected){
        ps.parking_zone.selected = false;
      }
      ps.groups.forEach(c=>{
        if(c.selected){
          c.selected = false;
        }        
      });
    });
    // console.log('clear'); // log
    model.query.runS();
  }
  // function selectOnlyList(l){
  //   clear();
  //   l.list.forEach(ps=>{
  //     ps.selected = 1;
  //   });
  //   l.selected = l.list.length;
  // }
  function toggleList(l){
    if(l.selected){
      l.selected = false;
      l.parking_spaces.forEach(c=>{
        c.selected = false;
        c.ps.selected = (c.ps.parking_zone.selected || c.ps.groups.some(c=>c.selected));
      });
    }else{
      l.selected = true;
      l.parking_spaces.forEach(c=>{
        c.selected = true;
        c.ps.selected = true;
      });
      if (typeof events.onListSelection === 'function') {
        events.onListSelection();
      }
    }
    // console.log('toggleList'); // log
    model.query.runS();
  }
  function selectPS(ps){
    ps.selected = true;
    ps.parking_zone.selected = true;
    ps.parking_zone.list.selected = ps.parking_zone.list.parking_spaces.every(c=>c.selected);
    ps.groups.forEach(c=>{
      c.selected = true;
      c.list.selected = c.list.parking_spaces.every(c=>c.selected);
    });
  }
  function deselectPS(ps){
    ps.selected = false;
    ps.parking_zone.selected = false;
    ps.parking_zone.list.selected = false;
    ps.groups.forEach(c=>{
      c.selected = false;
      c.list.selected = false;
    });
  }
  function togglePS(ps){
    if(ps.selected){
      deselectPS(ps);
    }else{
      selectPS(ps);
    }
    // console.log('togglePS'); // log
    model.query.runS();
  }

  function selectAllParkingSpaces(parkingSpaces) {
    parkingSpaces.forEach(ps => {
      selectPS(ps);
    });
    model.query.runS();
  }
  //   maintainSelection(){
  //   this.list.forEach(d=>{
  //     if(!d.visible)
  //       d.selected = 0;
  //   });
  // }
  // clearSelection(){
  //   this.list.forEach(d=>{
  //     d.selected = 0;
  //   });
  //   this.model.zones.list.forEach(z=>{z.selected = false;});
  //   this.model.query.run();
  // }
  // getSelected(){
  //   return this.list.filter(i=>(i.selected>0));
  // }
  return {
    events,
    clear,
    // selectOnlyList,
    toggleList,
    selectPS,
    deselectPS,
    togglePS,
    selectAllParkingSpaces,
  };
}
