/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


import Vue from 'vue';

import 'vue-awesome/icons/chevron-right';
import 'vue-awesome/icons/chevron-left';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/car';
import 'vue-awesome/icons/truck';
import 'vue-awesome/icons/wheelchair';
import 'vue-awesome/icons/eraser';
import 'vue-awesome/icons/search';
import 'vue-awesome/icons/crosshairs';
import 'vue-awesome/icons/at';
import 'vue-awesome/icons/globe';
import Icon from 'vue-awesome/components/Icon';
Vue.component('v-icon', Icon);

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import translations from './translations.js';
const i18n = new VueI18n({
  locale: window.localStorage.getItem('lang'),
  messages: translations,
});

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/hu';
moment.locale(window.localStorage.getItem('lang'));

import 'leaflet';

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
  position: 'bottom-right',
  // theme: 'bubble',
  duration : 30000,
  iconPack : 'fontawesome',
  action : {
    text : '❌',
    onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }
},  
});

import App from './app.vue';
import Stats from './stats';
import Account from './account';
import Settings from './settings';
import SettingsPaying from './settings/profiles-metered.vue';
import SettingsLoading from './settings/profiles-loading.vue';
import SettingsResidential from './settings/profiles-residential.vue';
import SettingsBinding from './settings/binding.vue';
import SettingsParkingSpaces from './settings/parkingspaces.vue';
import SettingsGroups from './settings/groups.vue';
import SettingsClosures from './settings/closures.vue';
import SettingsGroupAlertRules from './settings/groupalertrules.vue';

const router = new VueRouter({
  routes: [
    {name: 'stats', path: '/stats', component: Stats},
    {name: 'account', path: '/account', component: Account},
    {name: 'settings', path: '/settings', component: Settings, children: [
      {path: 'paying', component: SettingsPaying},
      {path: 'loading', component: SettingsLoading},
      {path: 'residential', component: SettingsResidential},
      {path: 'binding', component: SettingsBinding},
      {path: 'spaceedit', component: SettingsParkingSpaces },
      {path: 'groups', component: SettingsGroups},
      {path: 'closures', component: SettingsClosures},
      {path: 'groupalertrules', component: SettingsGroupAlertRules}
    ]},
    {path: '*', redirect: '/stats'}
  ]
});

Vue.filter('capitalize', function(value){
  if(!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

import model from '/model';

var app = new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App),
  watch: {
    '$i18n.locale': function(nv, ov){
      window.localStorage.setItem('lang', nv);
    }
  }
});

model.load()
.then(()=>{
  //console.log(model);
});
