<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div>
  <loading v-if="!model.loaded" :progress="model.progress"></loading>
  <div class="navbar" v-if="model.loaded">
    <div class="title">
      {{$t('title')}}
    </div>
    <div class="midlogo"><img src="../res/vfb_red.png"></div>
    <div class="button" v-if="model.companies.list.length > 1">
      <i class="icon icon-position"></i><span>{{$t('menu.companies')}}</span>
      <div class="dropdown">
        <div v-for="c in model.companies.list" class="ddi" @click="model.companies.select(c.id)">{{ c.name }}</div>
      </div>
    </div>
    <div class="button" v-if="!model.error">
      <i class="vficon isettings"></i><span>{{$t('common.edit')}}</span>
      <div class="dropdown">
        <router-link tag="div" class="ddi" to="/settings/spaceedit">{{$t('common.parking_spaces')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/groups">{{$t('common.parking_space_groups')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/binding" v-if="model.user.permissions.has('ParkingSpaceParkingSensor.Update')">{{$t('menu.settings.binding')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/closures">{{$t('common.closures')}}</router-link>
        <router-link v-if="model.user.allow.gar_read" tag="div" class="ddi" to="/settings/groupalertrules">{{$t('common.group_alert_rules')}}</router-link>
        <div class="separator"></div>
        <router-link tag="div" class="ddi" to="/settings/paying">{{$t('common.metered_hours')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/loading">{{$t('common.loading_hours')}}</router-link>
        <router-link tag="div" class="ddi" to="/settings/residential">{{$t('common.residential_hours')}}</router-link>
      </div>
    </div>
    <div class="button">
      <i class="vficon iuser"></i><span>{{$t('common.account')}}</span>
      <div class="dropdown">
        <router-link tag="div" class="ddi" to="/account"  v-if="!model.error">{{$t('common.settings')}}</router-link>
        <div class="ddi" v-if="model.user.is_admin" @click="navAdmin">{{$t('menu.account.admin')}}</div>
        <div class="separator"></div>
        <div class="ddi" @click="model.api.logout">{{$t('menu.account.logout')}}</div>
      </div>
    </div>
  </div>
  <div class="content" v-if="model.loaded && !model.error">
    <leftmenu></leftmenu>
    <side-by-side class="sbs" :close="model.hideMap">
      <template slot="leftpane">
        <m-map :selectedpss="selectedpss" :visibleselectedpss="visibleselectedpss" :highlightedId="model.parkingspaces.highlightedId"></m-map>
      </template>
      <template slot="rightpane">
        <router-view :selectedpss="selectedpss" :visibleselectedpss="visibleselectedpss"></router-view>
      </template>
    </side-by-side>
  </div>
  <div class="content" v-if="model.loaded && model.error">
    <div class="error-message">{{$t('common.app_init_error')}}</div>
  </div>
</div>
</template>

<style lang="less">
@import '/global';
@import '/common';
@import '../lib/eicon/icons';
// @import '../lib/hildafont/hilda';
@import '../lib/vffont/vodafone';
@import '../lib/vficon';

html, body{
  margin: 0;
  // font-family: "Ericsson Hilda", Helvetica, Arial, sans-serif;
  font-family: @app-font-family;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
  image-rendering: -webkit-optimize-contrast;
  --acc1: @color-accent;
  --acc2: #fecb00;
}

.navbar{
  position: fixed;
  height: @navbar-height;
  width: 100%;
  background-color: @navbar-bg;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
  display: flex;
  z-index: 10;
  .vendor(user-select, none);

  .title{
    font-size: 25px;
    height: @navbar-height;
    line-height: @navbar-height;
    margin-left: 10px;
    color: @navbar-fg;
    white-space: nowrap;

    .icon{
      margin-right: 8px;
    }
  }

  .midlogo{
    margin-left: auto;
    margin-right: auto;
    line-height: @navbar-height;
    img{
      height: 80%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .button{
    color: @color-accent-fg;
    margin: 10px 10px;
    background-color: @color-accent;
    border-radius: @border-radius;
    cursor: pointer;
    height: @navbar-height - 20px;
    line-height: @navbar-height - 20px;
    font-size: 19px;
    padding: 0px 10px;
    white-space: nowrap;

    &:hover{
      background-color: lighten(@color-accent, 10%);
    }

    .icon{
      display: inline-block;
      margin-bottom: 14px;
    }

    .icon, .vficon{
      margin-right: 8px;
    }

    span{
      vertical-align: top;
    }

    .dropdown{
      display: none;
      position: absolute;
      background-color: @color-base-bg;
      box-shadow: 0px 8px 16px 4px rgba(0,0,0,0.4);
      color: @color-base-fg;
      border-radius: @border-radius;
      margin-top: -14px;
      margin-left: -10px;

      .ddi{
        padding: 3px 10px;

        &:hover{
          background-color: darken(@color-base-bg, 20%);
        }
      }

      .separator{
        border-top: 1px solid @color-base-bg-dark;
      }

    }

    &:hover .dropdown{
      display: block;
    }
  }

}

.content{
  position: fixed;
  top: @navbar-height;
  height: calc(100% - @navbar-height);
  width: 100%;

  .sbs{
    position: absolute;
    height: 100%;
    width: calc(100% - @left-pane-width);
    left: @left-pane-width;
  }
}

.error-message {
  position: relative;
  margin: 64px auto;
  border: 2px dashed red;
  padding: 32px;
  font-size: 20px;
  width: 400px;
  text-align: center;
}

.toasted .primary.error,
.toasted.toasted-primary.error {
  position: relative;
  background-color: white !important;
  border: 2px solid red;
  color: black;
  display: block;
  padding: 16px 64px 16px 16px;
  .action {
    position: absolute;
    right: 8px;
    top: 8px;
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }
}
</style>

<script>
import model from '/model';
import Loading from './loading.vue';
import Leftmenu from './leftmenu';
import SideBySide from './utils/side-by-side.vue';
import MMap from './map';

import {urls} from '../config.yml';

export default {
  name: 'app',
  data: ()=>({model}),
  components: { Loading, Leftmenu, SideBySide, MMap },
  methods: {
    navAdmin(){
      window.open(urls.backend + '/admin/', '_blank');
    }
  },
  computed: {
    selectedpss(){
      return model.parkingspaces.parking_spaces.filter(ps=>ps.selected);
    },
    visibleselectedpss(){
      const mask = model.filter.mask;
      return model.parkingspaces.parking_spaces.filter(ps=>ps.isVisible(mask) && ps.selected);
    }
  },
}

</script>
