/********************************************************************************
 *                                                                              *
 * COPYRIGHT Ericsson 2019                                                      *
 *                                                                              *
 * The copyright to the computer program(s) herein is the property of Ericsson  *
 * AB. The programs may be used and/or copied only with written permission      *
 * from Ericsson AB. or in accordance with the terms and conditions stipulated  *
 * in the agreement/contract under which the program(s) have been supplied.     *
 *                                                                              *
 ********************************************************************************/


class Closure{
  constructor(o){
    Object.assign(this, o);
  }
}
export default async function closures(model){
  var data = {
    list: [],
    count: 0,
    pagecount: 0,
    nextUrl: null,
    page: 1,
    pageSize: 10,
    search: '',
    parking_spaces__id: null,
    date: null,
    filters: [
      {value:'U', active: true }, // Upcoming
      {value:'I', active: true }, // In progress
      {value:'P', active: false}  // Past
    ]
  }
  
  async function load(page){
    let actives = data.filters.filter(f=>f.active);
    let querystring = '';
    if (actives.length > 0 && actives.length != data.filters.length) {    
      querystring = '&status=' + actives.map(f=>f.value).join(',');
    }
    if (!!data.search) {
      querystring += '&search=' + data.search;
    }
    if (!!data.date) {
      querystring += '&date_timestamp=' + data.date.valueOf();
    }
    if (!!data.parking_spaces__id) {
      querystring += '&parking_spaces__id=' + data.parking_spaces__id;
    }
    if (data.pageSize) {
      querystring += '&page_size=' + data.pageSize;
    }
    if (page) {
      querystring += '&page=' + page;
    }
    return await getData('/closures/?ordering=-start' + querystring, false);
  }

  async function getData(url, extendList) {
    let res = await model.api.get(url);
    data.page = res.data.page;
    data.count = res.data.count;
    data.pagecount = res.data.page_count;
    data.nextUrl = res.data.next;
    const results = res.data.results.map(c=>{
      var nld = new Closure(c);
      // TODO: Lazy load, because parking spaces are already hidden in the grouped view
      loadPSs(nld, nld.parking_spaces);
      return nld;
    });

    if (extendList) {
      data.list.push(...results);
    }else{
      data.list = results;
    }
  }


  await load();

  function loadPSs(ld, psids){
    ld.parking_spaces = psids.map(ps=>model.parkingspaces ? model.parkingspaces.getById(ps) : ps);
  }

  return {
    data: data,
    editMode: false,

    async reload(){
      await load();
    },
    async loadMore(){
      if (data.nextUrl !== null) {
        await getData(data.nextUrl, true);
      }
    },
    async loadPage(pageIndex){
      await load(pageIndex);
    },
    getById(id){
      return closures.find(i=>(i.id==id));
    },
    setEditMode(value) {
      this.editMode = value;
    },
    async addLD(newvals){
      newvals.parking_spaces = newvals.parking_spaces.map(ps=>ps.id);
      let error;
      await model.api.post('/closures/', newvals).then(res => {
        var nld = new Closure(res.data);
        loadPSs(nld, nld.parking_spaces);
        data.list.unshift(nld);
      }).catch(e => {
        error = e;
      });
      return !error;
    },
    async deleteLD(ld){
      let error;
      await model.api.delete('/closures/' + ld.id + '/').then(() => {
        data.list.splice(closures.indexOf(ld), 1);
      }).catch(e => {
        error = e;
      });
      return !error;
    },
    async updateLD(ld, newvals){
      newvals.parking_spaces = newvals.parking_spaces.map(ps=>ps.id);
      let error;
      await model.api.patch('/closures/' + ld.id + '/', newvals).then(res => {
        ld.name = res.data.name;
        ld.start = res.data.start;
        ld.end = res.data.end;
        ld.deactivate_sensors = res.data.deactivate_sensors;
        ld.status = res.data.status;
        ld.comment = res.data.comment;
        loadPSs(ld, res.data.parking_spaces);
      }).catch(e => {
        error = e;
      });
      return !error;
    }
  };
}

