<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
 <div :class="{ tokenblock: true, box: edit }">
  <div class="header">
    <div v-if="deleting" @click="deleting=false;t.deleted=true" class="btn btn-block"><i class="icon icon-trashcan"></i></div>
    <div v-if="deleting" @click="deleting=false" class="btn btn-block"><i class="icon icon-cross"></i></div>
    <div v-if="allow.grouptoken_delete && !deleting && edit" @click="deleting=true" class="btn btn-block"><i class="icon icon-trashcan"></i></div>
  </div>
  <div class="edit attachbelow">
    <div>
      {{$t('settings.groupedit.token')}}
    </div>
    <div v-if="!edit"><div class="border">{{t.token}}</div></div>
    <input type="text" v-if="edit" v-model="t.token" :class="{error: errors.token}">
  </div>
  <div v-if="edit || t.valid_from != null" class="edit">
    <div class="sublabel">{{$t('settings.groupedit.validity')}}</div>
    <div v-if="!edit">
      <div class="border validity">{{t.valid_from}}</div> <span class="spacer">–</span> <div class="border validity">{{t.valid_to}}</div>
    </div>
    <div v-if="edit">
      <datetimepicker v-bind:value="t.valid_from" v-on:input="$event.setHours(12); t.valid_from = $event.toISOString().substr(0, 10)" :class="{error: errors.valid_from}" :datestringmode="true"></datetimepicker>
      <span class="spacer">–</span>
      <datetimepicker v-bind:value="t.valid_to" v-on:input="$event.setHours(12); t.valid_to = $event.toISOString().substr(0, 10)" :class="{error: errors.valid_to}" :datestringmode="true"></datetimepicker>
    </div>
  </div>
 <div>
</template>

<style lang="less">
@import '/global';
  .parkingspacegroup  .tokenblock {
    &.box {
      box-shadow: none;
    }

    .header {
      display: block;
      text-align: right;
    }

    .edit {
      &.attachbelow {
        margin-bottom: 15px;
      }
      input.flatpickr-input, .validity {
        width: 80px;
        display: inline-block;
      }
      .spacer {
        margin: 0 8px;
      }
    }
    .sublabel {
      padding-left: 15px;
    }
  }

.parkingspacegroup  .borderblock {
    border-top:    1px solid #eee;
    border-bottom: 1px solid #eee;
    border-collapse: collapse;
  }
</style>

<script>
import m from '/model';
import datetimepicker from '../stats/datetimepicker.vue';

const validators = {
  token: i=>i != null && i.length > 0 && i.length <= 32,
  valid_from: i=>i != null && i.match(/^\d{4}-\d{2}-\d{2}$/) != null,
  valid_to: i=>i != null && i.match(/^\d{4}-\d{2}-\d{2}$/) != null
};

export default {
  name: 'parkingspacegrouptoken',
  components: {datetimepicker},
  props: {
    owner: Object,
    edit: {
      type: Boolean,
      default: false
    },
    showerrors: {
      type: Boolean,
      default: false
    },
    t: Object
  },
  data(){
    return {
      allow: m.user.allow,
      deleting: false,
      errors: {
        token: false,
        valid_from: false,
        valid_to: false
      }
    };
  },
  watch: {
    showerrors(val){
      if(val){
        // var ok = true;
        for(let k in validators){
          if(!validators[k](this.t[k])){
            this.errors[k] = true;
            // ok = false;
          }else{
            this.errors[k] = false;
          }
        }
        if (this.t.valid_from > this.t.valid_to) {
          this.errors.valid_from = true;
          this.errors.valid_to = true;
        }
        // return ok;
      }else{
        for(let k in validators){
            this.errors[k] = false;
        }
      }
    }
  }
};
</script>
