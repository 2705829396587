<template>
  <div class="loader">Loading...</div>
</template>

<style lang="less" scoped>
@import '/global';

.loader,
.loader:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.loader {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 6px solid rgba(255, 0, 0, 0.2);
  border-right: 6px solid rgba(255, 0, 0, 0.2);
  border-bottom: 6px solid rgba(255, 0, 0, 0.2);
  border-left: 6px solid red;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  box-sizing: border-box;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>

export default{
  name: 'spinner',
  computed: {

  }
};
</script>
