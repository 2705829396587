<!------------------------------------------------------------------------------+
 |                                                                              |
 | COPYRIGHT Ericsson 2019                                                      |
 |                                                                              |
 | The copyright to the computer program(s) herein is the property of Ericsson  |
 | AB. The programs may be used and/or copied only with written permission      |
 | from Ericsson AB. or in accordance with the terms and conditions stipulated  |
 | in the agreement/contract under which the program(s) have been supplied.     |
 |                                                                              |
 +------------------------------------------------------------------------------>

<template>
<div class="pslist-menu" v-bind:class="{ working: working }">
  <div class="label pslist" :title="pslist.type ? $t('pgtypes.' + pslist.type) : null">
    <div @click="showDevices = !showDevices;" class="i">
      <v-icon :name="showDevices ? 'chevron-down' : 'chevron-right'" />
    </div>
    <div @click="clickList($event, pslist)" :class="[
      'l', 
      pslist.selected ? 'selected' : '', 
      selectionType == 2 ? 'full-selected' : '', 
      selectionType == 1 ? 'part-selected' : ''
    ]">{{pslist.name}} ({{pslist.parking_spaces.length}})</div>
  </div>
  <transition name="ht">
    <div v-if="showDevices">
      <div
        v-for="c in visiblepss"
        v-bind:key="c.ps.id"
        :class="[
          'label', 
          'device', 
          c.selected ? 'selected' : '',
          c.ps.selected ? 'part-selected' : '',
        ]"
        @click="clickPs($event, c.ps)"
      ><i class="icon i icon-alarm-level3" :class="c.ps.class"></i>{{c.ps.name}}</div>
    </div>
  </transition>
</div>
</template>

<style lang="less">
@import '/global';

.pslist-menu{
  &.working {
    .label {
      cursor: default;
    }
  }
  .label{
    padding: 5px 0;
    cursor: pointer;

    &.pslist{
      display: flex;
      margin-bottom: -10px;

      .i{
        flex: 0 0 auto;
        padding-right: 8px;
      }
      .l{
        flex: 1 0 auto;
        line-height: 18px;
      }
    }

    &.device{
      padding-left: 20px;

      .i{
        margin-right: 5px;

        &.unknown{color: @color-state-unknown;}
        &.occupied{color: @color-state-occupied;}
        &.free{color: @color-state-free;}
        &.closedoff{color: @color-state-closedoff;}
      }
    }
  }

  .part-selected {
    color: #800;    
  }
  .full-selected {
    font-weight: 600;
    color: #800;    
  }
  .selected{
    font-weight: 600;
    color: inherit;    
  }

  .ht-enter-active, .ht-leave-active{
    transition: max-height .2s ease-in-out;
    overflow-y: hidden;
  }
  .ht-enter, .ht-leave-to{
    max-height: 0px;
  }
  .ht-enter-to, .ht-leave{
    max-height: 1000px;
  }
}

</style>

<script>
import m from '/model';

export default {
  name: 'leftmenu-pslist',
  props: ['pslist'],
  data(){
     return{
      working: m.parkingspaces.working,
      showDevices: false,
      pscs: this.pslist.parking_spaces
     }
  },
  computed: {
    visiblepss(){
      const mask = m.filter.mask;
      return this.pscs.filter(psc=>psc.ps.isVisible(mask));
    },
    selectionType(){
      const pscs = this.pscs;
      const totalLength = pscs.length;
      if (totalLength > 0) {
        var selLength = pscs.filter(psc=>psc.ps.selected).length;
        if (selLength == totalLength) {
          return 2;
        } else if (selLength > 0) {
          return 1;
        }
      }
      return 0;
    },
  },
  methods: {
    clickList(e, pslist){
      if (m.parkingspaces.working || m.parkingsensors.editMode) {
        return undefined;
      }
      if(!(e.ctrlKey || e.shiftKey))
        m.selection.clear();
      m.selection.toggleList(pslist);
    },
    clickPs(e, ps){
      if (m.parkingspaces.working || m.parkingsensors.editMode) {
        return undefined;
      }
      if(!(e.ctrlKey || e.shiftKey))
        m.selection.clear();
      m.selection.togglePS(ps);
    }
  }
}
</script>
